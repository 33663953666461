<template>
  <div>
    <a-row class="table_item" @click.native="cameraClick(source)">
      <a-col :span="5" class="table_text table_cell_order_number_text">{{ index + 1 }}</a-col>
      <a-col :span="14" class="table_text table_cell_mileage_text">{{
          (classifyData.value === RESOURCE_TYPE.水运部门.value || classifyData.value === RESOURCE_TYPE.客运区域.value) ? source.cameraName : source.pileNum
        }}
      </a-col>
      <a-col :span="5" class="table_text table_status_error_text" v-if="source.lowStreamStatus == 0">离线</a-col>
      <a-col :span="5" class="table_text table_status_ok_text" v-else>在线</a-col>
    </a-row>
  </div>
</template>

<script>

import { RESOURCE_TYPE } from '../../core/enums'

export default {
  name: 'CameraVirtualListItem',
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default() {
        return {}
      }
    },
    classifyData: {
      type: Object,
      default() {
        return {}
      }
    },
    cameraClick: {
      type: Function,
      default() {
        return () => {}
      }
    }
  },
  data () {
    return {
      RESOURCE_TYPE
    }
  },
}
</script>
