<!--资源面板相机页面-->
<template>
  <div>
    <!--资源导航-->
    <div class="monitor-resources-freeway">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="#" @click.prevent="allClick">全部</a></a-breadcrumb-item>
        <a-breadcrumb-item>{{breadcrumb}}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <a-spin :spinning="loading">
      <!--相机类别筛选-->
      <div class="monitor-camera-select">
        <a-select :default-value="selectValueDefaultValue" style="width: 258px;" @change="classifyHandleChange">
          <a-select-option :value=classify.value v-for="(classify) in classifyDatas" :key="classify.id">
            {{classify.text}}
          </a-select-option>
        </a-select>
      </div>

      <!--相机列表-->
      <div class="monitor-camera">
        <div>
          <a-row class="table_header">
            <a-col :span="5" class="table_text table_header_text">序号</a-col>
            <a-col :span="14" class="table_text table_header_text">{{ (classifyData.value === RESOURCE_TYPE.水运部门.value || classifyData.value === RESOURCE_TYPE.客运区域.value) ? '名称' : '桩号' }}</a-col>
            <a-col :span="5" class="table_text table_header_text">状态</a-col>
          </a-row>
        </div>
        <virtual-list
            class="table_camera"
            :data-sources="cameraData"
            :data-key="'cameraNum'"
            :data-component="CameraVirtualListItem"
            :extra-props="{
              classifyData,
              cameraClick,
            }"
        />
<!--        ↑改用virtual-list优化渲染速度-->
<!--        <div class="table_camera">-->
<!--          <div v-for="(camera,index) in cameraData" :key="camera.cameraNum" @click="cameraClick(camera)">-->
<!--            <a-row class="table_item">-->
<!--              <a-col :span="5" class="table_text table_cell_order_number_text">{{index+1}}</a-col>-->
<!--              <a-col :span="14" class="table_text table_cell_mileage_text">{{ (classifyData.value === RESOURCE_TYPE.水运部门.value || classifyData.value === RESOURCE_TYPE.客运区域.value) ? camera.cameraName : camera.pileNum }}</a-col>-->
<!--              <a-col :span="5" class="table_text table_status_error_text" v-if="camera.lowStreamStatus == 0">离线</a-col>-->
<!--              <a-col :span="5" class="table_text table_status_ok_text" v-else>在线</a-col>-->
<!--            </a-row>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </a-spin>
  </div>

</template>

<script>

import Vue from 'vue'
import { listDicData } from '../../api/dic'
import { INDUSTRY_TYPE, RESOURCE_TYPE, DEPARTMENT_QUERY_TYPE } from '../../core/enums'
import VirtualList from 'vue-virtual-scroll-list'
import CameraVirtualListItem from './CameraVirtualListItem'

export default {
name: "Camera",
  props: {
    cameraData: {
      type: Array,
      default: () => []
    },
    classifyData: {
      type: Object,
      default: () => {}
    },
    resourceData: {
      type: Object,
      default: () => {}
    }
  },
  components: { VirtualList },
  computed: {
    breadcrumb () {
      let classifyData = this.classifyData
      let resourceData = this.resourceData
      if (classifyData.value === RESOURCE_TYPE.高速公路路线.value) {
        return resourceData.roadName
      } else if (classifyData.value === RESOURCE_TYPE.高速公路区域.value) {
        return resourceData.areaName
      } else if (classifyData.value === RESOURCE_TYPE.高速公路收费所.value) {
        return resourceData.name
      } else if (classifyData.value === 'highway_newroad') { // 新接路段
        return resourceData.name
      } else if (classifyData.value === RESOURCE_TYPE.国省干线区域.value) {
        return resourceData.name
      } else if (classifyData.value === RESOURCE_TYPE.水运部门.value) {
        return resourceData.name
      }
      return ''
    }
  },
  data () {
    return {
      CameraVirtualListItem,
      INDUSTRY_TYPE,
      RESOURCE_TYPE,
      DEPARTMENT_QUERY_TYPE,
      loading: false,
      classifyDatas:[],
      selectValueDefaultValue:'-1'
    }
  },
  methods:{
    cameraClick (camera) {
      this.$emit('cameraClick', camera)
    },
    getClassifyData () { // 加载选择框的数据
      let classifyAllItem = {
        id: -1,
        value:'-1',
        text:'全部'
      }
      this.classifyDatas.push(classifyAllItem)
      listDicData({
        type:'cameraClassify'
      }).then((res)=>{
        res.data.forEach((item)=>{
          let classifyItem = {
            id: item.id,
            value:item.code,
            text:item.name
          }
          this.classifyDatas.push(classifyItem)
        })
      })
    },
    classifyHandleChange(classifyValue){
      this.loading = true
      // 根据当前面板所在的不同类别封装不同的请求参数
      let param = {
        roleId: Vue.ls.get('user').roleIds[0],
        zoom: 100,
        road: '',
        areacode: '',
        department: '',
        classify: classifyValue
      }
      let classifyData = this.classifyData
      let resourceData = this.resourceData
      if (classifyData.value === RESOURCE_TYPE.高速公路路线.value) {
        param.road = resourceData.roadCode
        param.department = DEPARTMENT_QUERY_TYPE.甘肃省高速公路局.value
      } else if (classifyData.value === RESOURCE_TYPE.高速公路区域.value) {
        param.areacode = resourceData.areaCode
        param.department = DEPARTMENT_QUERY_TYPE.甘肃省高速公路局.value
      } else if (classifyData.value === RESOURCE_TYPE.高速公路收费所.value) {
        param.department = resourceData.name
      } else if (classifyData.value === 'highway_newroad') {
        param.department = resourceData.name
      } else if (classifyData.value === RESOURCE_TYPE.国省干线区域.value) {
        param.department = resourceData.name
      }

      // 查询范围锁定到甘肃
      param.startLng = 85.902031
      param.endLng = 115.367363
      param.startLat = 29.986922
      param.endLat = 45.099591

      this.$emit('getCameraData', param, ()=> {
        this.loading = false
      })
    },
    allClick () { // 点击全部按钮
      this.$emit('allClick')
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getClassifyData()
    })
  }
}
</script>
<style lang="less" scoped>

  @import "../../assets/style/camera-table";

  /*面包屑导航样式*/
  .monitor-resources-freeway /deep/ .ant-breadcrumb{
    color: #0088FF;
  }
  .monitor-resources-freeway /deep/ .ant-breadcrumb-separator {
    margin: 0 8px;
    color: #0088FF;
  }
  .monitor-resources-freeway /deep/ .ant-breadcrumb > span:last-child {
    width: 95px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.85);
    opacity: 0.85;
  }
  .monitor-resources-freeway /deep/ .ant-breadcrumb a {
    width: 52px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 22px;
    color: #0088FF;
  }
  .monitor-resources-freeway /deep/ .ant-breadcrumb a:hover {
    color: #74BEFF;
  }

  /*相机类型选择框样式*/
  .monitor-camera-select {
    margin-top: 16px;
  }

  /*.ant-select{*/
  /*  color: #0088FF;*/
  /*}*/
  /*.ant-select-dropdown-menu-item{*/
  /*  color: white;*/
  /*  opacity: 0.85;*/
  /*}*/
  /*.anticon{*/
  /*  color: #0088FF;*/
  /*}*/
  /*.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {*/
  /*  background-color: #0E3285;*/
  /*}*/

  /*.ant-select-dropdown-menu::-webkit-scrollbar {!*滚动条整体样式*!*/
  /*  width: 8px;     !*高宽分别对应横竖滚动条的尺寸*!*/
  /*  height: 1px;*/
  /*}*/
  /*.ant-select-dropdown-menu::-webkit-scrollbar-thumb {!*滚动条里面小方块*!*/
  /*  border-radius: 8px;*/
  /*  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
  /*  background: #2276F2;*/
  /*}*/
  /*.ant-select-dropdown-menu::-webkit-scrollbar-track {!*滚动条里面轨道*!*/
  /*  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
  /*  border-radius: 8px;*/
  /*  background: rgba(0,0,0,0.08)*/
  /*}*/

  /*输入框框样式*/
  .ant-input {
    background-color: #121459;
    border-color: #056bd6;
    color: #0088FF;
  }


</style>
