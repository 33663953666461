<!--
高德事件
     status=0或者当前时间-end_time超过3分钟即可认为事件已消散
     getAlertFusionInfo: 获取最新的事件信息，以alert_id,start_time,end_time为主键
     getAlertFusionLatest: 获取历史的事件信息，以alert_id为主键
-->
<template>
    <div>
        <a-tooltip placement="left" v-if="showVisibleButtonFlag">
            <template slot="title">
                事件统计
            </template>
            <img class="visible-button visible-button-show" @click="$refs.panelTitle.showPanel('event')" src="../assets/images/iconfont/btn_eventlists_normal.png">
        </a-tooltip>

<!--        <div style="height: 200px; width: 200px; background-color: red; position: absolute; z-index: 100; right: 10px; bottom: 20px;" class="animated bounceOutLeft"></div>-->

        <div class="monitor-event animated" ref="eventPanel" :class="{flipInY: !spinning && refreshNum > 1}" :style="{height: eventPanelHeight + 'px'}" v-if="visibleData.eventPanel">

            <!--四个边角-->
            <div class="panel-corner">
                <img :src="Corner.BorderLeftTop">
                <img :src="Corner.BorderRightTop">
                <img :src="Corner.BorderLeftBottom">
                <img :src="Corner.BorderRightBottom">
            </div>

            <div class="event-tabs">
                <div @click="panelType = 1" :class="{active: panelType === 1}" v-if="visibleData.eventCameraButton">
                    视频
                </div>
                <div @click="panelType = 2" :class="{active: panelType === 2}" style="margin-top: 14px;" v-if="visibleData.eventCameraButton">
                    报警
                </div>
                <div @click="panelType = 0" :class="{active: panelType === 0}" style="margin-top: 14px;" v-if="visibleData.eventTrafficButton">
                    交通
                </div>
            </div>

            <a-spin :spinning="spinning && refreshNum === 1">
            <div class="event-header">
                <!--            <div class="title">事件</div>-->
                <panel-title :title="title" :toolbar="3" :panelDom="$refs.eventPanel" @showVisibleButton="showVisibleButton" @hidePanel="hidePanel" @showPanel="showPanel" ref="panelTitle"/>
<!--                <div class="event-history" @click="showEventHistory">历史</div>-->
                <img class="event-history" v-if="panelType !== 2 && hasEventResource" @click="showEventHistory" src="../assets/images/iconfont/btn_records_normal.png" />
                <img class="event-arrow" :class="{'event-arrow-down': eventPanelExpand, 'event-arrow-up': !eventPanelExpand}" @click="panelExpandClick"  />
            </div>

                <!--交通(高德)事件-->
                <template v-if="panelType === 0">
                    <empty v-if="eventDatas.length === 0" :description="'暂无数据'"
                           style="height: 200px;"></empty>

                    <div v-else class="event-wrapper" :style="{height: eventPanelHeight - 58 + 'px'}">
                        <div class="event-item" v-for="(event, index) of eventDatas.slice(0, 5)" :key="index" @click="positioningEvent(event)">
                            <div class="event-item-icon">
                                <img style="width: 28px; height: 28px;" :src="EVENT_TYPE.properties[event['sub_evt_type_no']].src">
                                <img v-if="event.status == EVENT_STATUS.已结束" class="event-over-flag" src="../assets/images/iconfont/icon_event_over_flag.png">
                            </div>
                            <div class="event-item-info">
                                <div class="event-item-line">
                                    <span class="event-item-info-type">{{EVENT_TYPE.properties[event['sub_evt_type_no']].label | emptyFilter}}</span>
                                    <span class="event-item-info-time">{{formatMsgTime(event['start_time']) | emptyFilter}}</span>
                                </div>
                                <div class="event-item-line">
                                    <span class="event-item-info-road">{{event['road_code'] | emptyFilter}}</span>
                                    <span class="event-item-info-position">{{event['start_ioi_name'] | emptyFilter}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <!--视频事件-->
                <template v-else-if="panelType === 1">
                    <empty v-if="videoEventData.length === 0" :description="'暂无数据'"
                           style="height: 200px;"></empty>

                    <div v-else class="event-wrapper" :style="{height: eventPanelHeight - 58 + 'px'}">
                        <div class="event-item" v-for="(event, index) of videoEventData" :key="index" @click="positioningVideoEvent(event)">
                            <div class="event-item-icon">
                                <img style="width: 28px; height: 28px;" :src="VIDEO_EVENT_ENUM.img[event.alertType]">
                            </div>
                            <div class="event-item-info">
                                <div class="event-item-line">
                                    <span class="event-item-info-type">{{VIDEO_EVENT_ENUM.alertType[event.alertType] | emptyFilter}}</span>
                                    <span class="event-item-info-time">{{formatMsgTime(event['alertStartTime']) | emptyFilter}}</span>
                                </div>
                                <div class="event-item-line">
                                    <span class="event-item-info-position">{{event['deviceName'] | emptyFilter}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <!--报警(响铃)事件-->
                <template v-else-if="panelType === 2">
                    <empty v-if="videoEventAlarmData.length === 0" :description="'暂无数据'"
                           style="height: 200px;"></empty>

                    <div v-else class="event-wrapper" :style="{height: eventPanelHeight - 58 + 'px'}">
                        <div class="event-item" v-for="(event, index) of videoEventAlarmData" :key="index" @click="positioningVideoEvent(event)">
                            <div class="event-item-icon">
                                <img style="width: 28px; height: 28px;" :src="VIDEO_EVENT_ENUM.img[event.alertType]">
                            </div>
                            <div class="event-item-info">
                                <div class="event-item-line">
                                    <span class="event-item-info-type">{{VIDEO_EVENT_ENUM.alertType[event.alertType] | emptyFilter}}</span>
                                    <span class="event-item-info-time">{{formatMsgTime(event['alertStartTime']) | emptyFilter}}</span>
                                </div>
                                <div class="event-item-line">
                                    <span class="event-item-info-position">{{event['deviceName'] | emptyFilter}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

            </a-spin>

            <monitor-event-history-modal ref="eventHistoryModal"></monitor-event-history-modal>
            <monitor-video-event-history-modal ref="videoEventHistoryModal"></monitor-video-event-history-modal>
        </div>
    </div>

</template>

<script>

    import Vue from 'vue'
    import Corner from '../assets/images/corner'
    import {getEventList} from '../api/event'
    import {getAnalyseData} from '../api/cameraAnalyse'
    import {listResource} from '../api/resource'
    import {calcDateDifference, formatMsgTime} from '../utils/util'
    import {EVENT_TYPE, VIDEO_EVENT_ENUM, HIGHWAY_ENUM, EVENT_STATUS, RESOURCE_TYPE} from '../core/enums'
    import MonitorEventHistoryModal from "./monitor/MonitorEventHistoryModal"
    import MonitorVideoEventHistoryModal from "./monitor/MonitorVideoEventHistoryModal"
    import Empty from "../components/common/Empty"
    import PanelTitle from "./common/PanelTitle";

    let timeout = null

    export default {
        name: "MonitorEvent",
        components: {MonitorVideoEventHistoryModal, PanelTitle, MonitorEventHistoryModal, Empty},
        props: {
            eventHeight: {
                type: Number,
                default: 0
            }
        },
        computed: {
            title () {
                if (this.panelType === 0) {
                    return '交通事件'
                } else if (this.panelType === 1) {
                    return '视频事件'
                } else if (this.panelType === 2) {
                    return '报警事件'
                }
                return '事件'
            }
        },
        watch: {
            // 面板收缩状态,监听页面尺寸调整,防止出现面板重叠
            eventHeight (newValue, oldValue) {
                if (!this.eventPanelExpand) {
                    this.eventPanelHeight = this.eventPanelHeight + (newValue - oldValue)
                }
            }
        },
        data() {
            return {
                Corner, // 四个边角
                EVENT_TYPE,
                VIDEO_EVENT_ENUM,
                HIGHWAY_ENUM,
                EVENT_STATUS,
                load: false,
                spinning: false,
                panelType: 1, // 0: 高德事件, 1:视频事件, 2:报警(响铃)事件
                eventHistoryDatas: [], // 历史数据
                eventData: {}, // 当天最新一条数据
                eventDatas: [], // 当天事件数据
                videoEventData: [], // 视频事件数据(除报警外)
                videoEventAlarmData: [], // 视频事件报警数据
                showVisibleButtonFlag: false, // 是否展示面板的显隐按钮
                refreshNum: 0, // 控制刷新效果的flag,第一次进入不显示特效
                eventPanelExpand: false, // 事件面板是否展开
                eventPanelHeight: this.eventHeight, // 事件面板高度
                hasEventResource: false, // 是否有事件资源权限
                // update wxy 2023-11-7 09:55 根据权限控制页面元素是否展示
                visibleData: { // 根据权限控制是否显示
                    eventPanel: false, // 事件面板
                    eventTrafficButton: false, // 交通事件按钮
                    eventCameraButton: false, // 视频事件按钮&报警事件按钮
                },
            }
        },
        methods: {
            formatMsgTime,
            getEventData() { // 查询事件数据
                this.refreshNum++
                let param = {
                    dt: this.$moment(new Date()).format('YYYYMMDD'),
                    // dt: this.$moment("20210125").format('YYYYMMDD'),
                    data_version: '20200930',
                    adcode: '620100001'
                }
                this.spinning = true
                // 查询事件实时数据
                getEventList(param).then(res => {
                    this.spinning = false
                    this.eventDatas = res.data
                    this.load = true
                    // 进行中的排到已结束的前面
                    this.eventDatas.sort((a, b) => b.status - a.status)
                    // 地图撒点
                    this.createEventMarker(this.eventDatas)
                    this.spinning = false
                })
                // 查询事件历史数据
                // getEventHistoryList(param).then(res => {
                //     let data = res.data
                //     if (data !== null && data.length > 0) {
                //         this.eventHistoryDatas = data
                //         // 解析发生事件的路段全名
                //         this.eventHistoryDatas.forEach(eventData => {
                //             eventData.roadName = '-'
                //             eventData.roadCode = '-'
                //             eventData.roadLabel = '-'
                //             // 发生事件的高速名称
                //             let fusionJson = JSON.parse(eventData.fusion_json)
                //             let roadName = fusionJson.drdchl_info.length > 0 ? fusionJson.drdchl_info[0].name : ''
                //             let code = roadName.match(/[gGsS]\d+/g);
                //             if (code !== null && code.length > 0) {
                //                 let roadCode = code = code[0].toUpperCase() // 道路编号 G22
                //                 eventData.roadName = HIGHWAY_ENUM.properties[roadCode].value
                //                 eventData.roadCode = roadCode
                //                 eventData.roadLabel = HIGHWAY_ENUM.properties[roadCode].label
                //             }
                //         })
                //     }
                // })
            },
            getVideoEventData() { // 查询视频事件数据(除报警状态外)
                let param = {
                    department: '',
                    alertType: '',
                    startTime: this.$moment(new Date()).subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                    endTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                    deviceName: '',
                    fromCompany: '',
                    isAlarm: 2,
                    isConfirm: -1,
                    page: 1,
                    'page-size': 20
                }
                this.spinning = true
                // 查询事件实时数据
                getAnalyseData(param).then(res => {
                    this.spinning = false
                    this.videoEventData = res.data.data
                    this.load = true
                    // 地图撒点
                    this.createVideoEventMarker(this.videoEventData)
                    this.spinning = false
                })
            },
            getVideoEventAlarmData() { // 查询视频事件数据(报警未确认状态)
                let param = {
                    department: '',
                    alertType: '',
                    startTime: this.$moment(new Date()).subtract(1, 'hours').format('YYYY-MM-DD HH:mm:ss'),
                    endTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                    deviceName: '',
                    fromCompany: '',
                    isAlarm: 1,
                    isConfirm: 0,
                    page: 1,
                    'page-size': 20
                }
                this.spinning = true
                // 查询事件实时数据
                getAnalyseData(param).then(res => {
                    this.spinning = false
                    this.videoEventAlarmData = res.data.data
                    this.load = true
                    // 地图撒点
                    this.createVideoEventMarker(this.videoEventData)
                    this.spinning = false
                })
            },
            changeEventData(eventData) { // 改变事件数据
                this.eventData = eventData
            },
            calcDuration(start, end) {
                return calcDateDifference(start, end)
            },
            createEventMarker(data) { // 高德事件撒点
                // 仅对进行中的事件地图撒点
                this.$emit('createEventMarker', data)
            },
            createVideoEventMarker(data) { // 视频事件撒点
                this.$emit('createVideoEventMarker', data)
            },
            positioningEvent(event) { // 在地图上定位高德事件
                this.$emit('positioningEvent', event)
            },
            positioningVideoEvent(event) { // 在地图上定位视频事件
                this.$emit('positioningVideoEvent', event)
            },
            panelExpandClick () { // 点击面板展开/收起按钮
                if (this.eventPanelExpand) { // 面板是展开状态
                    this.shrinkPanel()
                } else {
                    this.expandPanel()
                }
            },
            hidePanel () { //隐藏面板
                // if(!isClick) { // 防止疯狂点击出事
                this.$refs.eventPanel.classList.remove('flipInY')
                this.$refs.eventPanel.classList.add('fadeOut')
                // isClick = true
                // this.panelDom.style.opacity = 0
                setTimeout(() => {
                    this.$refs.eventPanel.style.visibility = 'hidden'
                    // isClick = false
                }, 500)

                // 隐藏展开按钮
                this.showVisibleButton(true)
                // }
            },
            showPanel () { // 显示面板
                // isClick = false
                this.$refs.eventPanel.classList.remove('fadeOut')
                this.$refs.eventPanel.classList.add('fadeIn')

                this.$refs.eventPanel.style.visibility = 'visible'

                // 隐藏展开按钮
                this.showVisibleButton(false)
            },
            expandPanel () { // 展开面板
                this.eventPanelExpand = true
                this.eventPanelHeight = this.eventHeight + 530
                this.$eventBus.$emit('onlinePanelVisible', false)
            },
            shrinkPanel () { // 收缩面板
                this.eventPanelExpand = false
                this.eventPanelHeight = this.eventPanelHeight - 530
            },
            showEventHistory() { // 点击历史按钮
                if (this.panelType === 0) {
                    this.$refs.eventHistoryModal.show(this.eventDatas)
                } else {
                    this.$refs.videoEventHistoryModal.show()
                }
            },
            showVisibleButton (flag) {
                this.showVisibleButtonFlag = flag
            },
            changePanelType () {

            }
            // panelHide() { // 隐藏面板
            //     this.$refs.eventPanel.style.right = '-298px'
            //     this.panelVisible = false
            //     // this.arrow = Corner.ArrowRight
            // },
            // panelShow() { // 显示面板
            //     this.$refs.eventPanel.style.right = '12px'
            //     this.panelVisible = true
            //     // this.arrow = Corner.ArrowLeft
            // },
        },
        mounted() {
            this.$nextTick(() => {

                const roleId = Vue.ls.get('user').roleIds[0] // 角色id
                listResource(roleId).then(res => {
                    if (res.data.filter(item => item.leve1 === 'event').length > 0) { // 分配了事件资源权限才查询
                        this.hasEventResource = true
                        // this.getEventData()
                        this.getVideoEventData()
                        this.getVideoEventAlarmData()
                        /*
                        interval = setInterval(() => {
                            // 事件每1分钟刷新一次
                            // this.getEventData()
                            this.getVideoEventData()
                            this.getVideoEventAlarmData()
                        }, 60000)
                        */

                        // 使用setTimeout实现
                        clearTimeout(timeout)
                        timeout = null
                        const timeoutFn = () => {
                            timeout = setTimeout(() => {
                                this.getVideoEventData()
                                this.getVideoEventAlarmData()
                                timeoutFn()
                            }, 300000) // 5分钟
                        }
                        timeoutFn()

                        // 控制页面元素可见性
                        this.visibleData.eventPanel = true
                        if (res.data.filter(item => item.leve2 === RESOURCE_TYPE.交通事件.value).length > 0) {
                            this.visibleData.eventTrafficButton = true
                            this.panelType = 0
                        }
                        if (res.data.filter(item => item.leve2 === RESOURCE_TYPE.视频事件.value).length > 0) {
                            this.visibleData.eventCameraButton = true
                            this.panelType = 1
                        }
                        // 如果按钮权限都没有,则显示面板也没有意义
                        if (!this.visibleData.eventTrafficButton && !this.visibleData.eventCameraButton) {
                            this.visibleData.eventPanel = false
                        }
                    }
                })

                // 面板展开/收起
                this.$eventBus.$on('eventPanelExpand', (expand) => {
                    if (expand) {
                        this.expandPanel()
                    } else {
                        this.shrinkPanel()
                    }
                })

                // 刷新视频事件列表
                this.$nextTick(() => {
                    this.$eventBus.$on('refreshEventList', () => {
                        this.getVideoEventData()
                        this.getVideoEventAlarmData()
                    })
                })
            })
        },
        beforeDestroy() {
            clearTimeout(timeout)
            timeout = null
        }
    }
</script>
<style lang="less" scoped>

    .panel-corner {
        & img:nth-child(1) {
            position: absolute;
            top: -2px;
            left: -2px;
        }

        & img:nth-child(2) {
            position: absolute;
            right: -2px;
            top: -2px;
        }

        & img:nth-child(3) {
            position: absolute;
            bottom: -2px;
            left: -2px;
        }

        & img:nth-child(4) {
            position: absolute;
            right: -2px;
            bottom: -2px;
        }
    }

    .monitor-event {
        position: absolute;
        bottom: 25px;
        right: 12px;
        background-color: rgba(21, 23, 97, 0.85);
        height: 250px;
        color: rgba(255, 255, 255, 0.85);
        opacity: 1;
        z-index: 10;
        width: 290px;
        transition: height 0.6s;
        /*transition: right 0.6s;*/
        /*transition: opacity 0.5s !important;*/

    }

    .monitor-event-tall {

    }
    .monitor-event-short{

    }

    .event-tabs {
        background-color: rgba(21, 23, 97, 0.85);
        position: absolute;
        left: -24px;
        top: 14px;
        width: 24px;
        padding: 8px 0px;
        border-radius: 12px 0px 0px 12px;
        border-top: 1px solid #0088ff;
        border-left: 1px solid #0088ff;
        border-bottom: 1px solid #0088ff;


        div {
            cursor: pointer;
            &:hover {
                img {
                    filter: brightness(1.4);
                }
            }
        }

        div {
            /*width: 30px;*/
            /*height: 30px;*/
            /*margin: 8px;*/
            line-height: 18px;
            margin-left: 6px;
            color: #1296db;

            &:hover {
                filter: brightness(1.4);
            }
        }

        .active {
            filter: brightness(2.0);
        }
    }


    .event-header {
        .title {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 22px;
            color: #0088FF;
            opacity: 1;
            margin-top: 16px;
            margin-left: 16px;
        }

        .event-arrow {
            cursor: pointer;
            position: absolute;
            right: 40px;
            top: 12px;
            padding: 2px;
        }

        .event-arrow-up {
            content: url("../assets/images/iconfont/btn_uparrow.png");
            &:hover {
                content: url("../assets/images/iconfont/btn_uparrow_hover.png");
            }
        }
        .event-arrow-down {
            content: url("../assets/images/iconfont/btn_downarrow.png");
            &:hover {
                content: url("../assets/images/iconfont/btn_downarrow_hover.png");
            }
        }

        .event-history {
            /*background: #0088FF;*/
            /*border: 1px solid #0088FF;*/
            /*border-radius: 4px;*/
            /*color: #FFFFFF;*/
            cursor: pointer;
            position: absolute;
            right: 68px;
            top: 12px;
            padding: 2px;

            &:hover {
                content: url("../assets/images/iconfont/btn_records_hover.png");
            }
        }
    }

    .event-wrapper {
        height: 200px;
        overflow-y: auto;
    }

    /*单条事件数据*/
    .event-item {
        height: 60px;
        padding-left: 16px;
        cursor: pointer;
        position: relative;
        &:hover {
            filter: brightness(1.2);
        }
        &:after {
            content: '';
            height: 1px;
            width: 90%;
            background-color: #0088FF;
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            margin: auto;
        }

        .event-item-icon {
            margin-right: 12px;
            line-height: 60px;
            float: left;

            .event-over-flag {
                position: absolute;
                bottom: 4px;
                left: 12px;
            }
        }
        .event-item-info {
            padding-top: 10px;
            float: left;
            width: 206px;

            .event-item-line {
                height: 22px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                color: #00CBFF;
            }

            .event-item-info-type{
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #00CBFF;
            }
            .event-item-info-time{
                float:right;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.65);
            }
            .event-item-info-road{
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #00CBFF;
                border: 1px solid rgba(0, 203, 255, 0.45);
                border-radius: 6px;
                padding-left: 4px;
                padding-right: 4px;
            }
            .event-item-info-position{
                margin-left: 4px;
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #00CBFF;
                opacity: 0.65;
            }
        }
    }

    .event-body {
        padding-left: 16px;
        padding-right: 16px;
        overflow-y: auto;
        cursor: pointer;
    }

    .visible-button {
        position: absolute;
        right: 16px;
        bottom: 16px;
        cursor: pointer;
        z-index: 10;
    }

    .visible-button-hide {
        &:hover {
            content: url("../assets/images/iconfont/btn_closewindow_hover.png");
        }
    }

    .visible-button-show {
        &:hover {
            content: url("../assets/images/iconfont/btn_eventlists_hover.png");
        }
    }

</style>
