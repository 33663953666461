import { get, put } from '@/utils/request'
const URL = '/manage/cameraAnalyse'


/**
 第一个过滤条件
    是否报警（isAlarm）：是1，否0，全部-1
 第二个过滤条件
    是否确认(isConfirm)：已确认1，未确认0，全部-1

 2，-1 除了未确认以外的全部数据
 1，0 报警未确认
 1，1报警已确认
 1，-1报警全部数据(已确认和未确认)
 0，-1 不需要报警数据
 -1，-1 全部数据
 * @param p
 * @returns {*}
 */
// 根据时间查询摄像机事件
// export const getAnalyseData = p => get(URL + '/getAnalysePageDataByTime' , p)
// export const getAnalyseData = p => get(URL + '/getCameraEventPageData', p)
export const getAnalyseData = p => get(URL + '/getCameraEventPageDataWwd', p)


// 智能分析首页数据
export const getAnalyseEventData = p => get(URL + '/getAnalyseEventData', p)

// 近N天事件总数趋势
export const getAnalyseEventCountsFromDate = p => get(URL + '/getAnalyseEventCountsFromDate', p)

// 查询事件总数
export const getEventTotalCount = () => get(URL + '/getEventTotalCount')

// 查询热力图数据
export const getHeatMapData = p => get(URL + '/getHeatMapDate', p)

// 确认事件
export const confirmEvent = p => put(URL + '/confirmEvent', p)
