<!--左侧资源面板-->
<template>
    <div>
        <div v-if="resourceData.length > 0" class="layerControl animated" :class="{flipInY: !spinning && refreshNum > 1}" :style="{height: resourceHeight + 'px'}" ref="layerControl">

            <!--四个边角-->
            <div class="panel-corner">
                <img :src="Corner.BorderLeftTop">
                <img :src="Corner.BorderRightTop">
                <img :src="Corner.BorderLeftBottom">
                <img :src="Corner.BorderRightBottom">
            </div>

            <!--行业选择区域-->
            <div class="group-classify">
                <a-tooltip placement="right" v-for="industry of resourceData" :key="industry.value">
                    <template slot="title">
                        {{industry.label}}
                    </template>
                    <div @click="industryClick(industry)">
                        <img :src="industry.src">
                    </div>
                </a-tooltip>
                <!--搜索按钮-->
                <a-tooltip placement="right">
                    <template slot="title">
                        查找相机
                    </template>
                    <div @click="searchCamera">
                        <img src="../assets/images/iconfont/search.png" style="margin-bottom: 12px; position: relative;">
                    </div>
                </a-tooltip>
                <!--返回按钮-->
                <a-tooltip placement="right">
                    <template slot="title">
                        隐藏/显示
                    </template>
                    <div @click="resourcePanelVisible">
                        <img :src="arrow" style="margin-bottom: 12px; position: relative;">
                    </div>
                </a-tooltip>
            </div>

            <div class="layerContent">
                <div class="tabDiv">
                    <!--标题-->
                    <panel-title :title="industry.label"></panel-title>
                    <!--类别选择区域-->
                    <div v-if="industry.value !== INDUSTRY_TYPE.高速公路.value && panelType !== 'search'" style="width: 255px;margin-left: 16px;margin-top: 16px; padding-bottom: 16px; height: 48px;">
                        <a-row>
                            <a-col :span='24 / industry.data.length' v-for="(tab, index) of industry.data" :key="tab.value">
                                <div ref="tabItem" class="tabItem tabItem-default"
                                     :class="{'tabItem-active': index === 0}"
                                     @click="classifyClick($event, tab)">
                                    {{tab.label}}
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                    <div v-if="industry.value === INDUSTRY_TYPE.高速公路.value && panelType !== 'search'" style="width: 255px;margin-left: 16px;margin-top: 16px; padding-bottom: 16px; height: 48px;">
                        <a-row>
                            <a-col  span=5 v-for="(tab, index) of industry.data.slice(0,industry.data.length-1)" :key="tab.value">
                                <div ref="tabItem" class="tabItem tabItem-default"
                                     :class="{'tabItem-active': index === 0}"
                                     @click="classifyClick($event, tab)">
                                    {{tab.label}}
                                </div>
                            </a-col>
                            <a-col span=9 v-for="tab of industry.data.slice(industry.data.length-1)" :key="tab.value">
                                <div ref="tabItem" class="tabItem tabItem-default"
                                     @click="classifyClick($event, tab)">
                                    {{tab.label}}
                                </div>
                            </a-col>
                        </a-row>
                    </div>
                    <a-spin :spinning="spinning && refreshNum === 1">
                        <div style="width: 290px;">
                            <a-row>
                                <a-col :span=24>
                                    <div class="resource-list" :style="{height: resourceHeight - (panelType === 'search' ? 80:130) + 'px'}" ref="resourceList">
                                        <!--资源选择面板-->
                                        <monitor-resource-item v-if="panelType === 'resource'" :classify="classify" @resourceClick="resourceClick"></monitor-resource-item>
                                        <!--相机选择面板-->
                                        <monitor-resource-camera v-if="panelType === 'camera'"
                                                                 :classify-data="classify"
                                                                 :resource-data="resource"
                                                                 :camera-data="camera"
                                                                 @getCameraData="getCameraData"
                                                                 @allClick="allClick"
                                                                 @cameraClick="cameraClick" />
                                        <monitor-camera-search v-if="panelType === 'search'" @cameraClick="cameraClick"></monitor-camera-search>
                                    </div>
                                </a-col>
                            </a-row>
                        </div>
                    </a-spin>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import MonitorResourceItem from "../components/monitor/MonitorResourceItem";
    import MonitorResourceCamera from "../components/monitor/MonitorResourceCamera";
    import Corner from '../assets/images/corner'
    import { listResource, listHighwayRouteCameraPercentage, listHighwayAreaCameraPercentage, listHighwayDepartmentCameraPercentage, listPassengerAreaCameraPercentage } from '../api/resource'
    import { listHighwayNewRoadCameraPercentageTemp } from '../api/resource' // 临时--新接路段资源查询接口
    import { getCameras } from '../api/camera'
    import { INDUSTRY_TYPE, RESOURCE_TYPE, DEPARTMENT_QUERY_TYPE } from '../core/enums'
    import Vue from 'vue'
    import PanelTitle from "./common/PanelTitle";
    import MonitorCameraSearch from "./monitor/MonitorCameraSearch";

    let timeout = null

    export default {
        name: "MonitorResources",
        components: {PanelTitle, MonitorResourceItem, MonitorResourceCamera, MonitorCameraSearch },
        props: {
            resourceHeight: {
                type: Number,
                default: 0
            }
        },
        computed: {
            title () {
                switch (this.classify) {
                    case 'highway':
                        return '高速公路'
                    case 'road':
                        return '国省干线'
                    case 'water':
                        return '水运'
                    case 'passenger':
                        return '客运'
                }
                return ''
            }
        },
        data() {
            return {
                Corner, // 四个边角
                INDUSTRY_TYPE,
                RESOURCE_TYPE,
                DEPARTMENT_QUERY_TYPE,
                panelVisible: true, // 是否展示面板
                arrow: Corner.ArrowLeft,
                spinning: false, // 加载中...
                panelType: 'resource', // 资源面板类型(显示资源还是相机)
                resourceData: [], // 资源面板数据
                industry: {}, // 行业数据(高速公路,国省干线……)
                classify: {}, // 类别数据(路线,区域,收费所……)
                resource: {}, // 资源数据(具体路线,区域,收费所item……)
                camera: [], // 相机资源数据
                refreshNum: 0, // 控制刷新效果的flag,第一次进入不显示特效
                currentPanelStatus: { // 记录当前面板所展示状态,防止刷新丢失
                    industry: null, // 行业
                    classify: null, // 类型
                }
            }
        },
        methods: {
            industryClick(industry) { // 点击行业(高速,国省干线,水运,客运)
                if (!this.panelVisible) {
                    this.panelShow()
                }
                this.showResourcePanel()
                // this.industry = []
                this.industry = {}
                // this.classify = []
                this.classify = {}
                this.industry = industry
                this.$emit('changeCurrentIndustry', industry.value) // 设置当前选中的行业
                if (industry.data.length > 0) {
                    this.classify = industry.data[0]
                    if (this.classify.data.length > 0) {
                        this.$refs.tabItem.forEach(item => {
                            item.classList.remove('tabItem-active')
                        })
                        if (this.$refs.tabItem[0] !== undefined) {
                            this.$refs.tabItem[0].classList.add('tabItem-active')
                        }
                    }
                    // 查询资源在线率,默认查询第一个
                    this.getCameraPercentage(this.classify.data.map(item => item.value), this.classify.value)
                    // this.loadAllMapDevice()
                }
            },
            classifyClick(e, classify) { // 点击类别
                this.classify = classify
                this.showResourcePanel()
                // 标记选中状态
                this.$refs.tabItem.forEach(item => {
                    item.classList.remove('tabItem-active')
                })
                e.currentTarget.classList.add('tabItem-active')
                // 查询资源在线率,默认查询第一个
                this.getCameraPercentage(this.classify.data.map(item => item.value), this.classify.value)
                // this.loadAllMapDevice()
            },
            resourceClick (classify, resource) { // 点击某一个资源,查询相机数据
                this.resource = resource
                this.spinning = true
                let param = {
                    roleId: Vue.ls.get('user').roleIds[0],
                    zoom: 100,
                    road: '',
                    areacode: '',
                    department: '',
                    classify: -1,
                    // 地图可视范围
                    startLng: 0, // 起始经度
                    endLng: 0, // 结束经度
                    startLat: 0, // 起始纬度
                    endLat: 0, // 结束维度
                }
                let options = {
                    queryParam: {},
                    type: classify.value, // 数据的类型
                    cameraParam: param // 摄像机的查询参数
                }
                if (classify.value === RESOURCE_TYPE.高速公路路线.value) {
                    param.road = resource.roadCode
                    param.department = DEPARTMENT_QUERY_TYPE.甘肃省高速公路局.value
                } else if (classify.value === RESOURCE_TYPE.高速公路区域.value) {
                    param.areacode = resource.areaCode
                    options.queryParam.areaName = resource.areaName
                    param.department = DEPARTMENT_QUERY_TYPE.甘肃省高速公路局.value
                } else if (classify.value === RESOURCE_TYPE.高速公路收费所.value) {
                    param.department = resource.name
                } else if (classify.value === 'highway_newroad') {
                    param.department = resource.name
                } else if (classify.value === RESOURCE_TYPE.国省干线区域.value) {
                    param.department = resource.name
                } else if (classify.value === RESOURCE_TYPE.水运部门.value) {
                    param.department = resource.name
                } else if (classify.value === RESOURCE_TYPE.客运区域.value) {
                    param.areacode = resource.areaCode
                    options.queryParam.areaName = resource.areaName
                    param.department = DEPARTMENT_QUERY_TYPE.道路运输事业发展中心.value
                }
                // 查询相机数据
                this.getCameraData(param, () => {
                    this.spinning = false
                })
                // 地图定位,加载地图上相关设备
                this.$emit('resourcesChangeRefreshMap', options)
            },
            cameraClick(camera) { // 点击具体相机
                this.$emit('cameraClick', camera)
            },
            loadAllMapDevice (type) { // 加载地图上的全部设备
                // 查询地图上的设备
                let param = {
                    roleId: Vue.ls.get('user').roleIds[0],
                    zoom: 100,
                    road: '',
                    areacode: '',
                    department: type ? type : '',
                    classify: -1
                }
                let options = {
                    queryParam: {},
                    type: 'all', // 随便给个, 走else, 查询全部
                    cameraParam: param // 摄像机的查询参数
                }
                // 地图定位,加载地图上相关设备
                this.$emit('resourcesAllChangeRefreshMap', options)
            },
            resourcesChangeRefreshMap(options) {
                this.$emit('resourcesChangeRefreshMap', options)
            },
            searchCamera () { // 搜索相机
                if (!this.panelVisible) {
                    this.panelShow()
                }
                this.showSearchPanel()
                this.loadAllMapDevice(DEPARTMENT_QUERY_TYPE.全部.value)
                this.industry = {}
                this.classify = {}
                this.industry.label = '查找相机'
                this.$emit('changeCurrentIndustry', 'search') // 设置当前选中的行业
                // this.industry = industry
                // if (industry.data.length > 0) {
                //     this.classify = industry.data[0]
                //     if (this.classify.data.length > 0) {
                //         this.$refs.tabItem.forEach(item => {
                //             item.classList.remove('tabItem-active')
                //         })
                //         this.$refs.tabItem[0].classList.add('tabItem-active')
                //     }
                //     this.loadAllMapDevice()
                // }
            },
            resourcePanelVisible() { // 资源面板显示/隐藏
                if (this.panelVisible) {
                    this.panelHide()
                } else {
                    this.panelShow()
                }
            },
            panelHide() { // 隐藏面板
                this.$refs.layerControl.style.left = '-292px'
                this.panelVisible = false
                this.arrow = Corner.ArrowRight
                this.$eventBus.$emit('showLeftPanel', false)
            },
            panelShow() { // 显示面板
                this.$refs.layerControl.style.left = '12px'
                this.panelVisible = true
                this.arrow = Corner.ArrowLeft
                this.$eventBus.$emit('showLeftPanel', true)
            },
            /**
             * 调用不同后台接口查询相机在线率
             * @param ids 资源id数组
             * @param classify 资源类型
             */
            getCameraPercentage (ids, classify) {
                this.spinning = true
                const roleId = Vue.ls.get('user').roleIds[0] // 角色id
                let param = {roleId: roleId}
                if (classify === RESOURCE_TYPE.高速公路路线.value) {
                    // 查询高速公路-路线下的相机在线率
                    param.roadIds = ids + ''
                    listHighwayRouteCameraPercentage(param).then(res => {
                        res.data.forEach(item => {
                            item.label = item.roadName
                            item.value = item.id
                        })
                        this.resourceData.find(r => r.value === INDUSTRY_TYPE.高速公路.value).data
                            .find(r => r.value === RESOURCE_TYPE.高速公路路线.value).data = res.data
                    }).finally(() => {
                        this.spinning = false
                    })
                    this.loadAllMapDevice(DEPARTMENT_QUERY_TYPE.甘肃省高速公路局.value)
                } else if (classify === RESOURCE_TYPE.高速公路区域.value) {
                    param.areaIds = ids + ''
                    listHighwayAreaCameraPercentage(param).then(res => {
                        res.data.forEach(item => {
                            item.label = item.areaName
                            item.value = item.id
                        })
                        this.resourceData.find(r => r.value === INDUSTRY_TYPE.高速公路.value).data
                            .find(r => r.value === RESOURCE_TYPE.高速公路区域.value).data = res.data
                    }).finally(() => {
                        this.spinning = false
                    })
                    this.loadAllMapDevice(DEPARTMENT_QUERY_TYPE.甘肃省高速公路局.value)
                } else if (classify === RESOURCE_TYPE.高速公路收费所.value) {
                    param.departmentIds = ids + ''
                    listHighwayDepartmentCameraPercentage(param).then(res => {
                        // 只展示收费所
                        res.data = res.data.filter(item => item.parentId === "3000000000000000002")
                        res.data.forEach(item => {
                            item.label = item.name
                            item.value = item.id
                        })
                        this.resourceData.find(r => r.value === INDUSTRY_TYPE.高速公路.value).data
                            .find(r => r.value === RESOURCE_TYPE.高速公路收费所.value).data = res.data
                    }).finally(() => {
                        this.spinning = false
                    })
                    //当高速公路页签展示收费所时，查所有高管局下相机
                    this.loadAllMapDevice(DEPARTMENT_QUERY_TYPE.甘肃省高速公路局.value)
                } else if (classify === RESOURCE_TYPE.国省干线区域.value) {
                    param.departmentIds = ids + ''
                    listHighwayDepartmentCameraPercentage(param).then(res => {
                        // 只展示公路局
                        res.data = res.data.filter(item => item.parentId === "852125231815462912")
                        res.data.forEach(item => {
                            item.label = item.name
                            item.value = item.id
                        })
                        this.resourceData.find(r => r.value === INDUSTRY_TYPE.国省干线.value).data
                            .find(r => r.value === RESOURCE_TYPE.国省干线区域.value).data = res.data
                    }).finally(() => {
                        this.spinning = false
                    })
                    //当高速公路页签展示路线时，查所有相机
                    this.loadAllMapDevice(DEPARTMENT_QUERY_TYPE.甘肃省公路局.value)
                } else if(classify === 'highway_newroad') {
                    this.spinning = false
                    this.loadAllMapDevice(DEPARTMENT_QUERY_TYPE.甘肃省高速公路局.value)
                } else if(classify === RESOURCE_TYPE.水运部门.value) {
                    param.departmentIds = ids + ''
                    listHighwayDepartmentCameraPercentage(param).then(res => {
                        res.data = res.data.filter(item => item.id === "887713690655264768")
                        res.data.forEach(item => {
                            item.label = item.name
                            item.value = item.id
                        })
                        this.resourceData.find(r => r.value === INDUSTRY_TYPE.水运.value).data
                            .find(r => r.value === RESOURCE_TYPE.水运部门.value).data = res.data
                    }).finally(() => {
                        this.spinning = false
                    })
                    this.loadAllMapDevice(DEPARTMENT_QUERY_TYPE.甘肃省水运局.value)
                } else if(classify === RESOURCE_TYPE.客运区域.value) {
                    param.areaIds = ids + ''
                    param.department = DEPARTMENT_QUERY_TYPE.道路运输事业发展中心.value
                    listPassengerAreaCameraPercentage(param).then(res => {
                        res.data.forEach(item => {
                            item.label = item.areaName
                            item.value = item.id
                        })
                        this.resourceData.find(r => r.value === INDUSTRY_TYPE.客运.value).data
                            .find(r => r.value === RESOURCE_TYPE.客运区域.value).data = res.data
                    }).finally(() => {
                        this.spinning = false
                    })
                    this.loadAllMapDevice(DEPARTMENT_QUERY_TYPE.道路运输事业发展中心.value)
                }else { // TODO 点击了目前还没有提供接口的类别
                    this.spinning = false
                }
            },
            getResourceData () { // 查询资源数据
                this.spinning = true
                this.refreshNum++
                // if (this.$refs.layerControl !== undefined) {
                //     this.$refs.layerControl.classList.remove('flipInY')
                // }
                const roleId = Vue.ls.get('user').roleIds[0] // 角色id
                listResource(roleId).then(res => {
                    this.resourceData = []
                    res.data.filter(r => r.leve1 !== null && r.leve1 !== INDUSTRY_TYPE.事件.value && r.leve1 !== INDUSTRY_TYPE.菜单.value && r.leve1 !== RESOURCE_TYPE.视频在线数统计.value && r.leve2 === null && r.leve3 === null)
                        .forEach(r => { // 行业数据
                            let src
                            if (r.leve1 === INDUSTRY_TYPE.高速公路.value) {
                                src = require('../assets/images/iconfont/highway.png')
                            } else if (r.leve1 === INDUSTRY_TYPE.国省干线.value) {
                                src = require('../assets/images/iconfont/road.png')
                            } else if (r.leve1 === INDUSTRY_TYPE.客运.value) {
                                src = require('../assets/images/iconfont/passenger.png')
                            } else if (r.leve1 === INDUSTRY_TYPE.水运.value) {
                                src = require('../assets/images/iconfont/water.png')
                            } else if (r.leve1 === INDUSTRY_TYPE.客运.value) {
                                src = require('../assets/images/iconfont/passenger.png')
                            }
                            this.resourceData.push({
                                label: r.resourceName,
                                value: r.leve1,
                                data: [],
                                src: src
                            })
                        })
                    // 遍历类别
                    res.data.filter(r => r.leve1 !== null && r.leve1 !== INDUSTRY_TYPE.事件.value && r.leve1 !== INDUSTRY_TYPE.菜单.value && r.leve1 !== RESOURCE_TYPE.视频在线数统计.value && r.leve2 !== null && r.leve3 === null).forEach(r => {
                        this.resourceData.find(item => item.value === r.leve1).data.push({
                            label: r.resourceName,
                            value: r.leve2,
                            data: []
                        })
                    })

                    // 遍历资源
                    res.data.filter(r => r.leve1 !== null && r.leve1 !== INDUSTRY_TYPE.事件.value && r.leve1 !== INDUSTRY_TYPE.菜单.value && r.leve1 !== RESOURCE_TYPE.视频在线数统计.value && r.leve2 !== null && r.leve3 !== null).forEach(r => {
                        this.resourceData.find(item => item.value === r.leve1).data.find(item => item.value === r.leve2).data.push({
                            label: r.resourceName,
                            value: r.leve3
                        })
                    })
                    // 临时--加载新接路段数据(新接路段与高速公路绑定,有高速公路才有新接路段)
                    if (this.resourceData.find(item => item.value === INDUSTRY_TYPE.高速公路.value) !== undefined) {
                        this.tempNewRoadData()
                    }
                    // 页面初始化默认选中行业1,类别1
                    let industryIndex = 0
                    let classifyIndex = 0
                    // 数据定时刷新,保留刷新前所选的行业及类别
                    if (this.currentPanelStatus.industry !== null) {
                        industryIndex = this.resourceData.findIndex(v => v.value === this.currentPanelStatus.industry.value)
                        if (this.currentPanelStatus.classify !== null) {
                            classifyIndex = this.resourceData[industryIndex].data.findIndex(v => v.value === this.currentPanelStatus.classify.value)
                        }
                    }
                    if (this.resourceData !== null && this.resourceData.length > 0) {
                        this.industry = this.resourceData[industryIndex]
                        if (this.industry !== null && this.industry.data.length > 0) {
                            this.classify = this.industry.data[classifyIndex]
                            // 查询资源在线率,默认查询第一个
                            this.getCameraPercentage(this.classify.data.map(item => item.value), this.classify.value)
                            this.$emit('changeCurrentIndustry', this.industry.value) // 设置当前选中的行业
                        } else {
                            this.spinning = false
                        }
                    } else {
                        this.spinning = false
                    }
                    // if (this.$refs.layerControl !== undefined) {
                    //     this.$refs.layerControl.classList.add('flipInY')
                    // }
                })
            },
            tempNewRoadData () { // 临时--加载新接路段数据
                // 临时-新接路段数据
                this.resourceData.find(item => item.value === INDUSTRY_TYPE.高速公路.value).data.push({
                    label: '独立运营路段',
                    value: 'highway_newroad',
                    data: []
                })
                // 查询新接路段数据
                const roleId = Vue.ls.get('user').roleIds[0] // 角色id
                let param = {roleId: roleId}
                listHighwayNewRoadCameraPercentageTemp(param).then(res => {
                    res.data.forEach(item => {
                        item.label = item.name
                        item.value = item.id
                    })
                    this.resourceData.find(r => r.value === INDUSTRY_TYPE.高速公路.value).data
                        .find(r => r.value === 'highway_newroad').data = res.data
                }).finally(() => {
                    this.spinning = false
                })
            },
            getCameraData (param, spinCallback) {
                getCameras(param).then(res => {
                    this.camera = res.data
                    this.showCameraPanel()
                }).finally(() => {
                    if (spinCallback !== undefined) {
                        spinCallback() // 控制子组件的spin加载效果
                    }
                })
            },
            showCameraPanel () { // 资源面板切换为相机
                this.panelType = 'camera'
            },
            showResourcePanel () { // 资源面板切换为资源
                this.panelType = 'resource'
            },
            showSearchPanel () { // 资源面板切换为搜索
                this.panelType = 'search'
            },
            allClick () { // 点击"全部"按钮
                this.showResourcePanel()
                this.loadAllMapDevice()
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.getResourceData()

                /*
                // 定时自动刷新
                setInterval(() => {
                    if (this.panelType === 'resource') {
                        this.$nextTick(() => {
                            if (this.$refs.tabItem !== undefined && this.$refs.tabItem.length > 0) {
                                // this.$refs.tabItem.forEach(item => {
                                //     item.classList.remove('tabItem-active')
                                // })
                                // this.$refs.tabItem[0].classList.add('tabItem-active')
                                // this.$refs.resourceList.scrollTop = 0
                                this.showResourcePanel()
                                this.loadAllMapDevice()
                            }
                        })
                        // 记录当前面板状态并刷新还原
                        this.currentPanelStatus.industry = this.industry
                        this.currentPanelStatus.classify = this.classify
                        this.getResourceData()
                    }
                }, this.$refreshInterval)
                */

                // 使用setTimeout实现
                clearTimeout(timeout)
                timeout = null
                const timeoutFn = () => {
                    timeout = setTimeout(() => {
                        if (this.panelType === 'resource') {
                            this.$nextTick(() => {
                                if (this.$refs.tabItem !== undefined && this.$refs.tabItem.length > 0) {
                                    // this.$refs.tabItem.forEach(item => {
                                    //     item.classList.remove('tabItem-active')
                                    // })
                                    // this.$refs.tabItem[0].classList.add('tabItem-active')
                                    // this.$refs.resourceList.scrollTop = 0
                                    this.showResourcePanel()
                                    this.loadAllMapDevice()
                                }
                            })
                            // 记录当前面板状态并刷新还原
                            this.currentPanelStatus.industry = this.industry
                            this.currentPanelStatus.classify = this.classify
                            this.getResourceData()
                        }
                        timeoutFn()
                    }, this.$refreshInterval + 3000) // 随便加个时间,错开刷新时间,减轻服务器压力
                }
                timeoutFn()
            })
        },
        beforeDestroy() {
            clearTimeout(timeout)
            timeout = null
        }
    }
</script>
<style lang="less" scoped>

    .panel-corner {
        & img:nth-child(1) {
            position: absolute;
            top: -2px;
            left: -2px;
        }

        & img:nth-child(2) {
            position: absolute;
            right: -2px;
            top: -2px;
        }

        & img:nth-child(3) {
            position: absolute;
            bottom: -2px;
            left: -2px;
        }

        & img:nth-child(4) {
            position: absolute;
            right: -2px;
            bottom: -2px;
        }
    }

    .layerControl {
        width: 290px;
        height: 830px;
        position: absolute;
        left: 12px;
        top: 12px;
        z-index: 10;
        background: rgba(21, 23, 97, 0.85);

        transition: left 0.6s;
    }

    .layerContent {
        position: absolute;
        top: 5px;
    }

    .tabDiv {
    }

    .tabDiv .tabItem {
        /*width: 85px;*/
        height: 32px;
        cursor: pointer;
        opacity: 1;
        text-align: center;
        font-weight: 500;
        line-height: 30px;
        font-size: 14px;
        font-family: PingFang SC;

    }

    .tabItem-first {
        border-radius: 4px 0px 0px 4px;
    }

    .tabItem-last {
        border-radius: 0px 4px 4px 0px;
    }

    .tabDiv .tabItem-default {
        border: 1px solid #02418A;
        color: rgba(0, 136, 255, 0.65);
    }

    .tabDiv .tabItem-active {
        background: #0088FF;
        border: 1px solid #0088FF;
        font-weight: bold;
        color: #FFFFFF;
    }

    .tabDiv .tabItem-default:not(.tabItem-active):hover {
        background: rgba(0, 136, 255, 0.25);
        border: 1px solid #02418A;
    }

    .resource-list {
        overflow-y: auto;
        height: 700px;
        padding: 16px 16px 16px 16px;
    }

    .resource-list::-webkit-scrollbar { /*滚动条整体样式*/
        width: 2px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
    }

    .resource-list::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
        border-radius: 8px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #2276F2;
    }

    .resource-list::-webkit-scrollbar-track { /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.08)
    }

    .title {
        margin-left: 16px;
        margin-top: 16px;
        /*width: 128px;*/
        /*height: 22px;*/
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 22px;
        color: #0088FF;
        opacity: 1;
    }

    .group-classify {
        background-color: rgba(21, 23, 97, 0.85);
        position: absolute;
        right: -48px;
        top: 14px;
        width: 48px;
        border-radius: 0px 6px 6px 0px;
        border-top: 2px solid #0088ff;
        border-right: 2px solid #0088ff;
        border-bottom: 2px solid #0088ff;


        div {
            cursor: pointer;
            &:hover {
                img {
                    filter: brightness(1.4);
                }
            }
        }

        img {

            width: 30px;
            height: 30px;
            margin: 8px;
            /*animation: button-move 1s infinite;*/
            /*animation-direction:alternate;*/

            &:hover {
                filter: brightness(1.4);
            }
        }
    }

    @keyframes button-move {
        from {
            right: -2px;
        }
        to {
            right: 2px;
        }
    }

</style>
