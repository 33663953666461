import { get } from '@/utils/request'

const URL = '/manage/tour'

export const listTourOfPagination = p => get(URL + '/pagination', p)
export const listTour = () => get(URL)
export const getTour = p => get(URL + '/' + p)

// 分页查询轮巡相机
export const listTourCameraOfPagination = p => get(URL + '/camera/pagination', p)
// 根据tourId查询轮巡相机
export const listTourCamera = tourId => get(URL + '/camera/' + tourId)
