<!--视频事件详情-->
<template>
    <div id="player-container" class="player-container" v-if="visible" v-show="show" :style="{height: containerHeight}">

        <!--四个边角-->
        <div class="panel-corner">
            <img :src="Corner.BorderLeftTop">
            <img :src="Corner.BorderRightTop">
            <img :src="Corner.BorderLeftBottom">
            <img :src="Corner.BorderRightBottom">
        </div>

        <div id="panel-header" class="panel-header">
            <div class="panel-title">事件详情</div>
            <div class="play-close" @click="close"></div>
        </div>


        <div class="panel-body">
            <a-row style="margin-top: 24px;">
                <a-col :span="12">
                    <span class="item-label">摄像机名称：</span><span class="item-value">{{videoEventData.deviceName}}</span>
                </a-col>
                <a-col :span="12" style="text-align: right;">

                    <a-popover trigger="click" v-model="showConfirmPopover"
                               v-if="videoEventData.isAlarm === EVENT_ALARM_STATUS.未确认"
                               @visibleChange="confirmContentVisibleChange">
                        <template slot="content">
                            <div style="width: 200px; height: 60px;">
                                <a-textarea placeholder="请输入确认内容..." v-model="confirmContent"
                                            style="resize: none;"></a-textarea>
                            </div>
                            <div style="text-align: right; margin-top: 2px;">
                                <a-button size="small" style="margin-right: 8px;" @click="cancel">取消</a-button>
                                <a-button size="small" type="primary" @click="confirmEvent(videoEventData.id)">确认
                                </a-button>
                            </div>
                        </template>
                        <span class="control-button" style="margin-right: 12px;">事件确认</span>
                    </a-popover>

                    <span class="control-button" style="margin-right: 12px;"
                          @click="handleNearbyVideoClick(videoEventData)">
                        附近视频
                    </span>
                    <span class="control-button" style="margin-right: 12px;" @click="liveVideo">
                        查看直播
                    </span>
                    <span class="control-button" @click="historyVideo">
                        查看回放
                    </span>
                </a-col>
            </a-row>
            <a-row style="margin-top: 24px;">
                <a-col :span="12">
                    <span class="item-label">报警类型：</span><span class="item-value">{{VIDEO_EVENT_ENUM.alertType[videoEventData.alertType]}}</span>
                </a-col>
                <a-col :span="12">
                    <span class="item-label">设备名称：</span><span class="item-value">{{videoEventData.deviceId}}</span>
                </a-col>
            </a-row>
            <a-row style="margin-top: 16px;">
                <a-col :span="12">
                    <span class="item-label">报警开始时间：</span><span class="item-value">{{$moment(videoEventData.alertStartTime).format('YYYY-MM-DD HH:mm:ss')}}</span>
                </a-col>
                <a-col :span="12">
                    <span class="item-label">报警结束时间：</span><span class="item-value">{{$moment(videoEventData.alertEndTime).format('YYYY-MM-DD HH:mm:ss')}}</span>
                </a-col>
            </a-row>
            <a-row style="margin-top: 16px;">
                <a-col :span="12">
                    <span class="item-label">事件记录时间：</span><span class="item-value">{{$moment(videoEventData.alertCreationTime).format('YYYY-MM-DD HH:mm:ss')}}</span>
                </a-col>
            </a-row>

            <div class="dividing-line"/>

            <a-row style="margin-top: 16px;">
                <a-col :span="12">
                    <span class="item-label">确认状态：</span><span class="item-value">{{videoEventData.isAlarm === null ? '无需确认' : EVENT_ALARM_STATUS.properties[videoEventData.isAlarm].label}}</span>
                </a-col>
                <a-col :span="12" v-if="videoEventData.isAlarm === EVENT_ALARM_STATUS.已确认">
                    <span class="item-label">确认人：</span><span class="item-value">{{videoEventData.confirmer}}</span>
                </a-col>
            </a-row>
            <a-row style="margin-top: 16px;" v-if="videoEventData.isAlarm === EVENT_ALARM_STATUS.已确认">
                <a-col :span="12">
                    <span class="item-label">确认时间：</span><span class="item-value">{{$moment(videoEventData.confirmTime).format('YYYY-MM-DD HH:mm:ss')}}</span>
                </a-col>
                <a-col :span="12">
                    <span class="item-label">确认内容：</span><span class="item-value">{{videoEventData.confirmContent | emptyFilter}}</span>
                </a-col>
            </a-row>

            <div class="dividing-line"/>

            <a-row style="margin-top: 24px;">
                <a-col :span="12">
                    <span class="item-label">报警车道号：</span><span class="item-value">{{videoEventData.laneNo}}</span>
                </a-col>
                <a-col :span="12">
                    <span class="item-label">车道方向：</span><span class="item-value">{{VIDEO_EVENT_ENUM.laneDir[videoEventData.laneDir]}}</span>
                </a-col>
            </a-row>
            <a-row style="margin-top: 16px;">
                <a-col :span="12">
                    <span class="item-label">车道类型：</span><span class="item-value">{{VIDEO_EVENT_ENUM.laneType[videoEventData.laneType]}}</span>
                </a-col>
                <a-col :span="12">
                    <span class="item-label">车辆行驶方向：</span><span class="item-value">{{VIDEO_EVENT_ENUM.direction[videoEventData.direction]}}</span>
                </a-col>
            </a-row>
            <a-row style="margin-top: 16px;">
                <a-col :span="12">
                    <span class="item-label">车辆类型：</span><span class="item-value">{{VIDEO_EVENT_ENUM.type[videoEventData.type]}}</span>
                </a-col>
                <a-col :span="12">
                    <span class="item-label">车牌号码：</span><span class="item-value">{{videoEventData.licensePlate === '0' ? '-' : videoEventData.licensePlate}}</span>
                </a-col>
            </a-row>

            <div class="dividing-line"/>

            <div class="picture" style="margin-top: 16px;">
                <div class="item-label" style="float: left;">图片：</div>
                <a-row style="overflow-y: auto; max-height: 143px;">
                    <viewer>
                        <a-col style="margin-top: 8px;" :span="8" v-for="(item, index) of videoEventData.picture"
                               :key="index">
                            <img style="width: 240px; height: 135px;" :src="item">
                        </a-col>
                    </viewer>
                </a-row>
            </div>

            <template v-if="showNearbyVideo">
                <div class="dividing-line"/>

                <div class="item-label" style="margin-top: 16px;">附近视频：</div>
                <div class="nearby-video" :style="{height: '200px'}">
                    <div class="nearby-video-list">
                        <div class="nearby-video-list-item" :title="item.cameraName"
                             v-for="(item, index) of nearbyCameras" :key="item.cameraNum"
                             @click="handleCameraClick(item, index)"
                             :class="{active: currentPlayerVideoIndex === index}">
                            {{item.cameraName}}
                        </div>
                    </div>

                    <div class="nearby-video-player" style="height: 96%; width: 56%;" id="cameraVideoPlay"/>
                </div>
            </template>
        </div>

        <event-video-modal ref="eventVideoModal"></event-video-modal>

    </div>
</template>

<script>

    import Corner from '../../assets/images/corner'
    import {VIDEO_EVENT_ENUM, EVENT_ALARM_STATUS} from '../../core/enums'
    import EventVideoModal from './EventVideoModal'
    import {confirmEvent} from '../../api/cameraAnalyse'
    import {getCameraVideoUrl} from "../../api/cameraVideo"
    // import { getCameraVideoUrl } from "../../../api/cameraVideo"
    import notification from 'ant-design-vue/es/notification'

    export default {
        name: "VideoEventDetail",
        components: {EventVideoModal},
        data() {
            return {
                Corner, // 四个边角
                VIDEO_EVENT_ENUM,
                EVENT_ALARM_STATUS,
                visible: false, // 是否存在
                show: false, // 是否显示
                showConfirmPopover: false, // 是否显示确认气泡
                containerHeight: 'auto', // 面板高度
                player: {},
                videoEventData: null,
                confirmContent: '', // 确认内容
                nearbyCameras: [], // 附近摄像机列表
                showNearbyVideo: false, // 显示附近视频
                currentPlayerVideoIndex: 0, // 当前选中播放的视频
            }
        },
        methods: {
            aliyunPlayer(playUrl) {
                // eslint-disable-next-line no-undef
                this.player = new Aliplayer({
                        "id": 'cameraVideoPlay',
                        "source": playUrl,
                        // "width": "320px",
                        // "height": "180px",
                        // "videoWidth":"320px",
                        // "videoHeight":"180px",
                        "autoplay": true,
                        "isLive": true,
                        "rePlay": false,
                        "showBuffer": false,
                        "snapshot": true,
                        "playsinline": true,
                        "language": "zh-cn",
                        "enableStashBufferForFlv": true,
                        "preload": true,
                        "useH5Prism": true,
                        "liveRetry": 5,
                        "waitingTimeout": 3,
                        "controlBarVisibility": "hover",
                        "skinLayout": [
                            {"name": "bigPlayButton", "align": "blabs", "x": 30, "y": 80},
                            {"name": "liveDisplay", "align": "blabs", "x": 15, "y": -8},
                            {"name": "errorDisplay", "align": "tlabs", "x": 0, "y": 0},
                            {"name": "infoDisplay", "align": "cc"},
                            {
                                name: "H5Loading", align: "cc"
                            }
                        ]
                    }, function (player) {
                        player.play();
                    }
                )
                this.player.on('error', function () {
                    let errDom = document.getElementsByClassName('prism-ErrorMessage')
                    errDom[0].innerHTML = '<div style="color: #fff;font-size: 18px;text-align: center;margin-top: 80px;">播放失败</div>'
                });
            },
            getCameraPlayerUrl(camera) {
                this.dispose()
                let param = {
                    videoType: 0,
                    cameraNum: camera.cameraNum
                }
                getCameraVideoUrl(param).then(res => {
                    let playUrl = res.data.videoRequestUrl[0].hls_url
                    this.videoData = camera
                    this.aliyunPlayer(playUrl);
                }).catch(err => {
                    console.log(err)
                    let errUrl = 'https://spell.gstscc.cn/live/stream01.m3u8?auth_key=1607167862-0-0-f9cb9eed814bf510288e2c4e454283ed'
                    this.aliyunPlayer(errUrl)
                })
            },
            dispose() { // 断开流
                if (this.player.dispose !== undefined) {
                    this.player.dispose() // 先关闭之前的再播放新的
                }
            },
            open(videoEventData) { // 从无到有,打开窗口
                videoEventData.cameraName = videoEventData.deviceName
                this.videoEventData = videoEventData
                this.nearbyCameras = []
                this.showNearbyVideo = false
                this.containerHeight = 'auto'
                this.visible = true
                this.show = true
            },
            close() {
                this.visible = false
            },
            handleCameraClick(camera, index) { // 点击相机,播放视频
                this.currentPlayerVideoIndex = index
                this.getCameraPlayerUrl(camera)
            },
            handleNearbyVideoClick(videoEventData) { // 点击附近视频
                this.showNearbyVideo = !this.showNearbyVideo // 重复点击,切换显隐状态
                this.nearbyCameras = []
                if (this.showNearbyVideo) {
                    this.containerHeight = '86%'
                    this.$emit('getNearbyCameras', videoEventData.longitude, videoEventData.latitude, (nearbyCameras) => {
                        this.nearbyCameras.push(videoEventData) // 上报事件的摄像机
                        for (let i = 0; i < nearbyCameras.length; i++) {
                            if (nearbyCameras[i].cameraNum !== videoEventData.cameraNum && this.nearbyCameras.length < 5) { // 最多展示5个摄像机
                                this.nearbyCameras.push(nearbyCameras[i])
                            }
                        }
                        this.currentPlayerVideoIndex = 0
                        this.getCameraPlayerUrl(videoEventData)
                    })
                } else {
                    this.containerHeight = 'auto'
                }
            },
            liveVideo() {
                this.$refs.eventVideoModal.show(this.videoEventData, 1)
            },
            historyVideo() {
                this.$refs.eventVideoModal.show(this.videoEventData, 2)
            },
            cancel() {
                this.showConfirmPopover = false
                this.confirmContent = ''
            },
            confirmContentVisibleChange(status) {
                if (!status) { // 隐藏
                    this.confirmContent = ''
                }
            },
            confirmEvent(id) {
                let param = {
                    id: id,
                    confirmTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                    confirmContent: this.confirmContent
                }
                confirmEvent(param).then(() => {
                    this.showConfirmPopover = false
                    notification.success({
                        message: '确认成功',
                        description: '该事件已确认成功！'
                    })
                    this.$eventBus.$emit('refreshEventList') // 刷新事件列表
                    this.$eventBus.$emit('refreshBellStatus') // 刷新响铃状态
                    this.confirmContent = ''
                    this.close()
                })
            }
        },
        mounted() {
            this.$nextTick(() => {
                // console.log(this.nearbyCameras)
            })

        }
    }
</script>

<style lang="less" scoped>

    .player-container /deep/ video {
        object-fit: fill !important;
        pointer-events: none;
    }

    .panel-corner {
        & img:nth-child(1) {
            position: absolute;
            top: -2px;
            left: -2px;
        }

        & img:nth-child(2) {
            position: absolute;
            right: -2px;
            top: -2px;
        }

        & img:nth-child(3) {
            position: absolute;
            bottom: -2px;
            left: -2px;
        }

        & img:nth-child(4) {
            position: absolute;
            right: -2px;
            bottom: -2px;
        }
    }

    .dividing-line {
        width: 794px;
        height: 1px;
        background: #0088FF;
        opacity: 0.45;
        margin-top: 24px;
    }

    .player-container {

        position: absolute;
        margin: 0px auto;

        left: 0px;
        right: 0px;
        top: 28px;

        /*top: -780px;*/
        /*left: 520px;*/
        /*right: 0px;*/
        /*margin: auto;*/
        width: 826px;
        height: 86%;
        /*height: 660px;*/
        z-index: 99;
        background-color: rgba(21, 23, 97, 0.85);
        padding: 16px;
        transition: width 0.5s;

        .panel-header {
            /*height: 42px;*/

            .panel-title {
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: bold;
                /*line-height: 42px;*/
                color: #0088FF;
            }

            .play-close {
                position: absolute;
                right: 20px;
                top: 12px;
                width: 16px;
                height: 16px;
                cursor: pointer;
                background-image: url("../../assets/images/cameraPlayer/btn_closewindow_normal.png");

                &:hover {
                    background-image: url("../../assets/images/cameraPlayer/btn_closewindow_hover.png")
                }
            }
        }

        .panel-body {

            overflow-y: scroll;
            overflow-x: hidden;
            height: 98%;

            .control-button {

                border: 1px solid #0088FF;
                border-radius: 17px;
                width: 56px;
                height: 20px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                line-height: 21px;
                color: #0088FF;
                padding: 4px 12px;
                cursor: pointer;

                &:hover {
                    filter: brightness(1.2);
                }
            }

            .item-label {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #0088FF;
            }

            .item-value {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.85);
            }
        }
    }

    .nearby-video {
        display: flex;

        .nearby-video-list {
            float: left;
            width: 40%;

            .nearby-video-list-item {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: rgba(255, 255, 255, 0.85);
                cursor: pointer;
                margin-top: 12px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:hover {
                    color: #06a4d9;
                }
            }

            .active {
                color: #06a4d9;
            }
        }

        .nearby-video-player {
            right: -32px;
            /*right: 16px;*/
            /*bottom: 16px;*/
            /*position: absolute;*/
        }
    }


</style>
