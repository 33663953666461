<!--九分屏轮巡-->
<template>
  <div :class="{'panel-mask': visible}">
    <div id="player-container" class="player-container" :style="{width: playContainerWidth + 'px'}" v-if="visible" v-show="show">

      <!--四个边角-->
      <div class="panel-corner">
        <img :src="Corner.BorderLeftTop">
        <img :src="Corner.BorderRightTop">
        <img :src="Corner.BorderLeftBottom">
        <img :src="Corner.BorderRightBottom">
      </div>

      <div id="panel-header" class="panel-header">
        <div class="panel-title">视频轮巡
<!--            <a-button size="small" type="primary" style="margin-left: 12px;" @click="cameraListShow = !cameraListShow">轮巡选择</a-button>-->
            <span class="control-button" style="margin-left: 12px;"
                  @click="cameraListShow = !cameraListShow">
                轮巡选择
            </span>
        </div>
<!--        <div class="play-zoom" :class="{'play-zoom-big': zoomStatus === 1, 'play-zoom-small': zoomStatus === 0}" @click="zoom"></div>-->
        <div class="play-close" @click="close"></div>
      </div>

      <a-row type="flex" justify="center">

<!--        <a-col :span="12" v-for="(item, index) of cameras" :key="index">-->
<!--          <div :id="'camera-player-' + (index + 1)" class="single-video">-->
<!--            <div class="single-video-header">G30甘肃定西定西段K1588+800下行</div>-->
<!--          </div>-->
<!--        </a-col>-->
          <!-- v-for直接循环数字是从1开始的,为了操作索引方便,在这里减1 -->
          <a-col :span="8" v-for="index in 9" :key="index">
              <div class="single-video" :style="{height: singleVideoHeight + 'px'}" ref="singleVideo">
                  <div :id="'camera-nine-player-' + (index - 1)" class="camera-player"></div>
                  <div class="single-video-header">
                      <div class="single-video-title" :style="{width: zoomStatus === 0 ? '80%' : '120px'}"></div>
<!--                      <img @click="oneScreenClick($event, (index - 1))" class="btn-one-screen" src="../../../assets/images/cameraPlayer/btn_one_screen_normal.png">-->
<!--                      <img @click="endPlayClick($event, (index - 1))" class="btn-close-video" src="../../../assets/images/cameraPlayer/btn_close_video_window_normal.png">-->
                  </div>
              </div>
          </a-col>
          <a-progress v-show="showProgress" :percent="tourSwitchPercent" status="normal" :show-info="false"/>
      </a-row>

        <div class="list-container" v-show="cameraListShow">

            <!--四个边角-->
            <div class="panel-corner">
                <img :src="Corner.BorderLeftTop">
                <img :src="Corner.BorderRightTop">
                <img :src="Corner.BorderLeftBottom">
                <img :src="Corner.BorderRightBottom">
            </div>

            <div class="panel-header">
                <div class="panel-title">轮巡列表</div>
                <!--        <div class="play-zoom" :class="{'play-zoom-big': zoomStatus === 1, 'play-zoom-small': zoomStatus === 0}" @click="zoom"></div>-->
                <div class="play-close" @click="cameraListClose"></div>
            </div>

            <a-spin :spinning="tourLoading">
                <empty v-if="tourList.length === 0" :description="'暂无数据'" style="height: 660px;"></empty>

                <div v-else class="camera-list">
                    <div v-for="item of tourList" :key="item.id" :class="{'item-active': activeTourId === item.id}">
                        <div @click="tourClick(item)" class="camera-list-item">
                            <div style="width: 75%; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;" :title="item.name">{{ item.name }}</div>
                            <div style="opacity: 0.65">{{ item.tourInterval }}<span style="font-size: 14px;"> 秒</span></div>
                        </div>
                    </div>
                </div>
            </a-spin>
        </div>
    </div>
  </div>
</template>

<script>

  import Corner from '../../../assets/images/corner'
  import Empty from "../../../components/common/Empty"
  import { getCameraVideoUrl } from "../../../api/cameraVideo"
  import { listTour, listTourCamera } from "../../../api/tour"
  import notification from 'ant-design-vue/es/notification'

  // 进度条
  let progressInterval
  // 轮巡切换
  let tourInterval

export default {
  name: "NineScreenTour",
  components: { Empty },
  data(){
    return{
      Corner, // 四个边角
      player1: {},
      player2: {},
      player3: {},
      player4: {},
      player5: {},
      player6: {},
      player7: {},
      player8: {},
      player9: {},
      visible: false, // 是否存在
      show: false, // 是否显示
      playContainerWidth: 1100,
      singleVideoHeight: 220, // 单个视频的高度
      zoomStatus: 0, // 面板放大还是缩小的标记 0:放大, 1:缩小
      cameras: [], // 四分屏数据
      videoIndex: -1, // 四分屏全局指针,记录添加到第几个了(从1开始)
      playerStatus:[ // 记录四个屏幕的状态,用于控制接下来的视频被哪个窗口播放
          {
              index: 0, // 1号窗口
              hasVideo: false, // 是否有视频
              selected: false, // 是否被选中
              cameraNum: null, // 相机编号(防止重复播放相同视频)
              cameraData: null // 相机数据
          },
          {
              index: 1,
              hasVideo: false,
              selected: false,
              cameraNum: null,
              cameraData: null
          },
          {
              index: 2,
              hasVideo: false,
              selected: false ,
              cameraNum: null,
              cameraData: null
          },
          {
              index: 3,
              hasVideo: false,
              selected: false ,
              cameraNum: null,
              cameraData: null
          },
          {
              index: 4,
              hasVideo: false,
              selected: false,
              cameraNum: null,
              cameraData: null
          },
          {
              index: 5,
              hasVideo: false,
              selected: false ,
              cameraNum: null,
              cameraData: null
          },
          {
              index: 6,
              hasVideo: false,
              selected: false ,
              cameraNum: null,
              cameraData: null
          },
          {
              index: 7,
              hasVideo: false,
              selected: false,
              cameraNum: null,
              cameraData: null
          },
          {
              index: 8,
              hasVideo: false,
              selected: false ,
              cameraNum: null,
              cameraData: null
          },
      ],
      tourList: [], // 轮巡列表
      tourCameraList: [], // 某个轮巡的相机列表
      tourSwitchPercent: 0, // 轮巡切换进度
      showProgress: false,
      cameraListShow: false, // 相机列表是否显示
      activeTourId: null, // 激活的轮巡
      tourLoading: false // 是否加载完毕,防止疯狂点击
    }
  },
  methods:{
    aliyunPlayer (playUrl, id, index) {
      // eslint-disable-next-line no-undef
        let player = new Aliplayer({
                "id": id,
                "source": playUrl,
                "width": "100%",
                "height": "100%",
                // "videoWidth":"220px",
                // "videoHeight":"180px",
                "autoplay": true,
                "isLive": true,
                "rePlay": false,
                "showBuffer": false,
                "snapshot": true,
                "playsinline": true,
                "language": "zh-cn",
                "enableStashBufferForFlv": true,
                "preload": true,
                "useH5Prism": true,
                "liveRetry": 5,
                "waitingTimeout": 3,
                "controlBarVisibility": "hover",
                "skinLayout": [
                  {"name": "bigPlayButton","align": "blabs","x": 30,"y": 80},
                  {"name": "liveDisplay","align": "blabs","x": 15, "y": -8},
                  {"name": "errorDisplay","align": "tlabs","x": 0,"y": 0},
                  {"name": "infoDisplay","align": "cc"},
                  {
                    name: "H5Loading", align: "cc"
                  }
                ]
              },function(player) {
                player.play();
              }
        )
        player.on('error',function(){
            let errDom = document.getElementsByClassName('prism-ErrorMessage')
            errDom[0].innerHTML = '<div style="color: #fff;font-size: 18px;text-align: center;margin-top: 80px;">播放失败</div>'
        });
        switch (index) {
            case 0:
                this.player1 = player
                break
            case 1:
                this.player2 = player
                break
            case 2:
                this.player3 = player
                break
            case 3:
                this.player4 = player
                break
            case 4:
                this.player5 = player
                break
            case 5:
                this.player6 = player
                break
            case 6:
                this.player7 = player
                break
            case 7:
                this.player8 = player
                break
            case 8:
                this.player9 = player
                break
        }
    },
    cameraPlayClose(){
      // this.player.dispose()
      if (this.player.dispose !== undefined) {
        this.player.dispose() // 先关闭之前的再播放新的
      }
    },
    open () { // 从无到有,打开窗口
        this.videoIndex = -1
        this.visible = true
        this.show = true
        // this.bindDrag()
        // this.addVideo(videoData)
        this.listTour()
    },
    showWindow () { // 从隐藏到显示(1分屏切回4分屏)
          this.show = true
      },
    cameraListClose () {
        this.cameraListShow = false
    },
    close () {
        this.visible = false
        // this.$store.nineScreen = false // 是否处于九分屏状态
        this.$emit('onClose')
        this.showProgress = false
        this.cameraListShow = false
        this.activeTourId = null
        clearInterval(tourInterval)
        clearInterval(progressInterval)
        this.clearAll()
    },
    clearAll () {
        this.playerStatus.forEach(p => {
            p.selected = false
            p.hasVideo = false
            p.cameraNum = null
            p.cameraData = null
        })
        this.destroyVideo(this.player1)
        this.destroyVideo(this.player2)
        this.destroyVideo(this.player3)
        this.destroyVideo(this.player4)
        this.destroyVideo(this.player5)
        this.destroyVideo(this.player6)
        this.destroyVideo(this.player7)
        this.destroyVideo(this.player8)
        this.destroyVideo(this.player9)
        // 清空名称文本
        document.getElementsByClassName("single-video-title").forEach(d => {
            d.innerHTML = ''
        })
    },
    listTour () {
        listTour().then(res => {
            this.tourList = res.data
        })
    },
    // 点击某个轮巡
    tourClick (tour) {
        this.activeTourId = tour.id

        if (this.tourLoading) {
            return
        }
        this.tourLoading = true

        // 结束之前的全部
        if (tourInterval) {clearInterval(tourInterval)}
        if (progressInterval) {clearInterval(progressInterval)}
        this.clearAll()
        this.tourCameraList = []

        listTourCamera(tour.id).then(res => {

            if (res.data.length === 0) {
                notification.error({
                    message: '未配置相机',
                    description: '该轮巡暂无相机'
                })
                return
            }
            this.tourCameraList = res.data
            this.startTour(tour) // 开始轮巡
        })
    },
    // 计算轮巡相机分组
    calcTourCamera () { // 计算本次轮巡的相机
        let arr = []
        for (let i = 0; i < this.tourCameraList.length; i++) {
            let smallArr = []
            for (let j = 0; j < 9; j++) {
                if (this.tourCameraList[i + j]) {
                    smallArr.push(this.tourCameraList[i + j])
                } else {
                    break
                }
            }
            i += 8
            arr.push(smallArr)
        }
        return arr
    },
    // 开始轮训
    startTour (tour) {
        let currentIndex = 0
        let _this = this
        let arr = this.calcTourCamera()
        if (tourInterval) {clearInterval(tourInterval)}
        function fn () {
            _this.tourSwitchPercent = 0
            _this.clearAll()
            arr[currentIndex].forEach((camera, index) => {
                _this.addVideo(camera, index)
            })
            if (progressInterval) {clearInterval(progressInterval)}
            progressInterval = setInterval(() => {
                _this.tourSwitchPercent += 100 / tour.tourInterval
            }, 1000)
            if (currentIndex < arr.length - 1) {
                currentIndex++
            } else {
                currentIndex = 0
            }
            _this.tourLoading = false
            return fn
        }

        if (arr.length > 1) {
            this.showProgress = true
            tourInterval = setInterval(fn(), tour.tourInterval * 1000 + 1000)
        } else if (arr.length === 1) { // 相机数<=9个
            this.showProgress = false
            this.clearAll()
            arr[0].forEach((camera, index) => {
                this.addVideo(camera, index)
            })
            this.tourLoading = false
        }

    },
    destroyVideo (player) { // 销毁视频
      if (player.dispose !== undefined) {
          try {
              player.dispose()
          } catch (err) {
              // console.error(err)
          }
      }
    },
    addVideo (camera, playIndex) { // 向四分屏中添加视频
      let param=  {
        videoType: 0,
        cameraNum: camera.cameraNum
      }

        this.playerStatus[playIndex].cameraNum = camera.cameraNum
        this.playerStatus[playIndex].cameraData = camera

      getCameraVideoUrl(param).then(res=>{
        let playUrl = res.data.videoRequestUrl[0].hls_url
        let id = 'camera-nine-player-' + playIndex
        this.aliyunPlayer(playUrl, id, playIndex);
        document.getElementById(id).nextSibling.style.display = 'block'
        document.getElementById(id).nextSibling.getElementsByClassName("single-video-title")[0].innerHTML = camera.cameraName
      }).catch(err=>{
        console.log(err)
        // let errUrl = 'https://spell.gstscc.cn/live/stream01.m3u8?auth_key=1607167862-0-0-f9cb9eed814bf510288e2c4e454283ed'
        // this.aliyunPlayer(errUrl)
      })
        this.$nextTick(() => {
            // 清空选中状态
            this.playerStatus.forEach(p => {
                p.selected = false
            })
            this.$refs.singleVideo.forEach(v => {
                v.classList.remove('single-video-selected')
            })
        })
    },
  },
  mounted() {

  }
}
</script>

<style lang="less" scoped>

  .player-container /deep/ video {
    object-fit: fill !important;
    pointer-events: none;
  }

  .panel-corner {
  & img:nth-child(1) {
      position: absolute;
      top: -2px;
      left: -2px;
    }

  & img:nth-child(2) {
      position: absolute;
      right: -2px;
      top: -2px;
    }

  & img:nth-child(3) {
      position: absolute;
      bottom: -2px;
      left: -2px;
    }

  & img:nth-child(4) {
      position: absolute;
      right: -2px;
      bottom: -2px;
    }
  }

  .player-container {
    position: absolute;
    top: 40px;
    left: 420px;
    /*right: 0px;*/
    /*margin: auto;*/
    /*width: 880px;*/
    /*height: 660px;*/
    z-index: 99;
    background-color: rgba(21, 23, 97, 0.85);
    padding: 0px 16px 16px 16px;
      transition: width 0.5s;

    .panel-header {
      height: 42px;
      /*cursor: move;*/

        .panel-title {
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 42px;
            color: #0088FF;
        }

      .play-zoom {
          position: absolute;
          right: 46px;
          top: 12px;
          width: 16px;
          height: 16px;
          cursor: pointer;
      }
        .play-zoom-big {
            background-image: url("../../../assets/images/cameraPlayer/btn_big_normal.png");
            &:hover {
                background-image: url("../../../assets/images/cameraPlayer/btn_big_hover.png")
            }
        }
        .play-zoom-small {
            background-image: url("../../../assets/images/cameraPlayer/btn_small_normal.png");
            &:hover {
                background-image: url("../../../assets/images/cameraPlayer/btn_small_hover.png")
            }
        }

      .play-close{
        position: absolute;
        right: 20px;
        top: 12px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        background-image: url("../../../assets/images/cameraPlayer/btn_closewindow_normal.png");

        &:hover {
          background-image: url("../../../assets/images/cameraPlayer/btn_closewindow_hover.png")
        }
      }
    }

    .single-video {
      /*height: 300px;*/
      position: relative;
      background-color: black;
      border: 1px solid rgba(255, 255, 255, .15);
      display: flex;
      overflow: hidden;
      transition: height .5s;

      &:after {
        content: 'No Video';
        margin: auto;
        left: 0px;
        right: 0px;
        color: rgba(255, 255, 255, .6);
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
      }

        .camera-player {
            position: absolute;
            /*pointer-events: none;*/
        }

      .single-video-header {
        z-index: 999;
        /*background-color: red;*/
        /*background: linear-gradient(to bottom, #706c6d 0%, #dddddd 100%);*/
        background: rgba(0, 0, 0, .65);
        opacity: .9;
        position: absolute;
        width: 100%;
        height: 24px;
        line-height: 24px;
        top: -32px;
        transition: top .3s;
        padding-left: 8px;
        color: rgba(255, 255, 255, .85);
        font-family: PingFang SC;
        font-weight: 400;
        display: none;
        white-space: nowrap;

          .single-video-title {
              width: 80%;
              text-overflow: ellipsis;
              overflow: hidden;
          }
          img {
              position: absolute;
              top: 3px;
              cursor: pointer;
          }
          .btn-one-screen {
              right: 30px;
              &:hover {
                  content: url("../../../assets/images/cameraPlayer/btn_one_screen_hover.png");
              }
          }
          .btn-close-video {
              right: 10px;
              &:hover {
                  content: url("../../../assets/images/cameraPlayer/btn_close_video_window_hover.png");
              }
          }
      }

      &:hover .single-video-header {
        top: 0px;
      }
    }

      .single-video-selected {
          box-shadow:0px 0px 5px 2px #0088ff;
      }

  }


  .player-container /deep/ .ant-progress-outer {
      padding-right: 0px;
      margin-right: 0px;
  }
  .player-container /deep/ .ant-progress-text {
      visibility:hidden ;
  }
  .player-container /deep/ .ant-progress-bg{
      background-color: #0088FF;
  }
  .player-container  /deep/ .ant-progress-inner{
      background-color: #383B76;
  }
  .player-container /deep/ .anticon {
      visibility: hidden;
  }
  .player-container /deep/ .ant-progress-status-success .ant-progress-bg {
      background-color: #0088FF;
  }

    .list-container {
        position: absolute;
        top: 0px;
        left: -408px;
        width: 400px;
        background-color: rgba(21, 23, 97, 0.96);
        height: 718px;
        padding: 0px 16px 16px 16px;

        .camera-list {
            max-height: 660px;
            overflow-y: auto;
        }

        .camera-list-item {
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.85);
            cursor: pointer;
            padding: 12px;
            border-bottom: 1px solid #0A49A6;
            display: flex;
            justify-content: space-between;

            &:hover {
                background-color: rgba(0, 136, 255, 0.25);
            }
        }

        .item-active {
            background-color: rgba(0, 136, 255, 0.25);
        }
    }

    .panel-mask {
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        z-index: 10;
    }

  .control-button {
      border: 1px solid #0088FF;
      border-radius: 17px;
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 21px;
      color: #0088FF;
      padding: 4px 12px;
      cursor: pointer;

      &:hover {
          filter: brightness(1.2);
      }
  }


</style>
