<!--空数据-->
<template>

  <div class="empty-wrapper">
    <a-empty
            :image-style="{height: '30px'}"
    >
      <img slot="image" src="../../assets/images/iconfont/empty.png">
      <span slot="description" class="empty-text">{{ description }}</span>
    </a-empty>
  </div>
</template>

<script>
export default {
    name: 'Empty',
    props: {
        description: {
            type: String,
            default: '暂无数据'
        }
    }
}
</script>

<style lang="less" scoped>

  // 垂直水平居中
  .empty-wrapper {
    height: 100%;
    display: flex;
    justify-content:center;/*水平居中*/
    align-items:center;/*垂直居中*/

    .empty-text {
      font-size:14px;
      font-family:Microsoft YaHei;
      font-weight:400;
      line-height:14px;
      color: rgba(255, 255, 255, 0.85);;
    }
  }

</style>
