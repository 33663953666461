// 高德事件接口
import { get } from '../utils/request'

// http://api-citybrain.cn-shanghai.aliyun.com/datafusion/dataApi/call/getEventFusionList?dt=20210106&data_version=20200930&adcode=620100001

const URL = '/manage/event'

// 查询事件
export const getEventList = p => get(URL, p)
// 查询事件历史
export const getEventHistoryList = p => get(URL + '/history', p)
