<!--搜索相机面板-->
<template>
  <div class="search-condition">
    <div>
      <span class="search-title">名称：</span><a-input v-model="searchData.cameraName" class="search-input" placeholder="请输入名称"/>
    </div>
    <div style="margin-top: 16px;">
      <span class="search-title">桩号：</span><a-input v-model="searchData.pileNum" class="search-input" placeholder="请输入桩号"/>
    </div>
<!--    <div style="margin-top: 16px;">-->
<!--      <span class="search-title">相机类型：</span>-->
<!--      <a-select default-value="-1" style="width: 70%;" :value="searchData.type" @change="handleChange">-->
<!--        <a-select-option value="-1">-->
<!--          全部-->
<!--        </a-select-option>-->
<!--        <a-select-option value="0">-->
<!--          隧道-->
<!--        </a-select-option>-->
<!--        <a-select-option value="1">-->
<!--          收费站-->
<!--        </a-select-option>-->
<!--        <a-select-option value="2">-->
<!--          服务区-->
<!--        </a-select-option>-->
<!--        <a-select-option value="3">-->
<!--          门架-->
<!--        </a-select-option>-->
<!--        <a-select-option value="4">-->
<!--          外场-->
<!--        </a-select-option>-->
<!--       </a-select>-->
<!--    </div>-->
    <div class="control-button">
      <a-button style="width: 48%; margin-right: 4%;" @click="reset">重置</a-button>

      <a-tooltip :visible="showTooltip" placement="right">
        <template slot="title" >
          请至少输入一个查询条件
        </template>
        <a-button style="width: 48%;" type="primary" @click="query">查询</a-button>
      </a-tooltip>
    </div>

    <div class="monitor-camera">
      <div>
        <a-row class="table_header">
          <a-col :span="5" class="table_text table_header_text">序号</a-col>
          <a-col :span="14" class="table_text table_header_text">桩号</a-col>
          <a-col :span="5" class="table_text table_header_text">状态</a-col>
        </a-row>
      </div>
      <a-spin :spinning="spinning">

        <empty v-if="cameraData.length === 0" :description="'暂无数据'"
               style="height: 250px;"></empty>

        <div v-else class="table_camera">
          <div v-for="(camera,index) in cameraData" :key="camera.cameraNum" @click="cameraClick(camera)">
            <a-row class="table_item" :title=camera.cameraName>
              <a-col :span="5" class="table_text table_cell_order_number_text">{{index+1}}</a-col>
              <a-col :span="14" class="table_text table_cell_mileage_text">{{camera.pileNum}}</a-col>
              <a-col :span="5" class="table_text table_status_error_text" v-if="camera.lowStreamStatus == 0">离线</a-col>
              <a-col :span="5" class="table_text table_status_ok_text" v-else>在线</a-col>
            </a-row>
          </div>
        </div>
      </a-spin>
    </div>

  </div>
</template>

<script>

  import Vue from 'vue'
  import { getCamerasByCondition } from '../../api/camera'
  import Empty from "../../components/common/Empty"

export default {
  name: "MonitorCameraSearch",
  components: { Empty },
  data() {
    return {
      searchData: {
        cameraName: '',
        pileNum: '',
        type: '-1'
      },
      cameraData: [], // 相机列表
      spinning: false, // 加载中
      showTooltip: false // 是否输入了查询内容
    }
  },
  methods: {
    handleChange (type) {
      this.searchData.type = type
    },
    reset () {
      this.searchData.cameraName = ''
      this.searchData.pileNum = ''
      this.searchData.type = '-1'

      this.cameraData = []
    },
    cameraClick (camera) {
      this.$emit('cameraClick', camera)
    },
    query (e) {
      e.stopPropagation()
      if (this.searchData.cameraName === '' && this.searchData.pileNum === '') {
          this.showTooltip = true
          return false
      }
      this.spinning = true
      let param = {
        roleIds: Vue.ls.get('user').roleIds[0],
        cameraName: this.searchData.cameraName.trim(),
        cameraNum: '',
        pileNum: this.searchData.pileNum.trim(),
        page: '1',
        'page-size': '99999'
      }
      // 根据条件查询相机
      getCamerasByCondition(param).then(res => {
        this.cameraData = res.data.data
      }).finally(() => {
        this.spinning = false
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      document.body.onclick = () => {
        this.showTooltip = false
      };
    })
  }
}
</script>
<style lang="less" scoped>

  @import "../../assets/style/camera-table";

  .search-condition {

    overflow-y: hidden;

    .search-title {
      color: #0088ff;
    }

    .search-input {
      width: 80%;
    }

    .control-button {
      margin-top: 16px;
      text-align: right;
    }

    input::-webkit-input-placeholder {
      color: #0d5699;
    }
    input:-moz-placeholder {
      color: #0d5699;
    }
    input::-moz-placeholder {
      color: #0d5699;
    }
    input:-ms-input-placeholder {
      color: #0d5699;
    }
  }

  .monitor-camera /deep/ .table_camera {
    overflow-y: auto;
    height: 580px;
  }

</style>
