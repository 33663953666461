export const OS_NAME = '视频云平台管理系统'

/**
 * 弹出层属性
 * @type {{WIDTH: number}}
 */
export const MODAL = {
    WIDTH: 1000, // 弹出层宽度
    MASK_CLOSABLE: false, // 点击蒙层是否允许关闭
    KEYBOARD: true // 是否支持键盘 esc 关闭
}


/**
 * 用户模块提示文案
 * @type {{SELECT_USER: string, SAVE_SUCCESSS: string, RESET_PWD: string, DELETE_SUCCESS: string, PED_DIFFERENT: string, SAVE_FAIL: string}}
 */
export const userText = {
    UPDATE_SUCCESSS: '用户更新成功',
    SAVE_SUCCESSS: '用户保存成功',
    SAVE_FAIL: '用户保存失败',
    SELECT_USER: '至少选择一个用户',
    NEED_PWD: '请输入你的账户密码',
    DELETE_SUCCESS: '用户删除成功',
    RESET_PWD: '密码重置成功',
    UPDATE_PED: '密码修改成功',
    PED_DIFFERENT: '两次密码不一致',
    START: '你确定要启用该账户吗',
    FORBIDDEN: '你确定要禁用该账户吗',
    START_SUCCESS: '用户启用成功',
    FORBIDDEN_SUCCESS: '用户禁用成功',
    ACCOUNT_EXIST: '该账号已存在，请重新输入',
    OLD_PASSWORD_ERROR: '原密码输入错误',
    VALIDATOR: {
        ACCOUNT: '请输入账号',
        ACCOUNT_MAX_LEN: '最大长度为20',
        PASSWORD: '请输入8-18位密码',
        CURRENT_PASSWORD: '请输入当前密码',
        CONFORM: '请输入确认密码',
        CONFIRM_PASSWORD:'输入的两次密码不一致',
        WEAK_PASSWORD:'密码复杂度太低(密码中应包含数字和字母)',
        WEAK_PASSWORD_TITLE: '当前密码复杂度太低，为保证账户安全，请先修改密码!',
        NAME_MAX_LEN: '最大长度为20',
        PHONE: '请输入手机号',
        PHONE_TRUE: '请输入正确的手机号',
        EMAIL: '请输入邮箱',
        EMAIL_TRUE: '请输入正确的邮箱格式',
        EMAIL_MAX_LEN: '最大长度为50',
        ROLE: '请选择角色',
        ORG: '请选择组织',
        NAME: '请输入姓名'
    }
}