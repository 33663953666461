<!--相机图标颜色图例-->
<template>
    <div class="map-layer-control" ref="legendPanel">

        <!--四个边角-->
        <div class="panel-corner">
            <img :src="Corner.BorderLeftTop">
            <img :src="Corner.BorderRightTop">
            <img :src="Corner.BorderLeftBottom">
            <img :src="Corner.BorderRightBottom">
        </div>

        <div class="img-wrapper">
            <span class="legend-item" @click="legendClick('sd')">
                <img src="../../assets/images/map/icon_map_sxj_sd_online.png"> 隧道
                <a-checkbox :defaultChecked="true" :checked="checkedData.sd" />
            </span>
            <span class="legend-item" @click="legendClick('sfz')" style="margin-left: 12px;">
                <img style="margin-left: 8px;" src="../../assets/images/map/icon_map_sxj_sfz_online.png"> 收费站
                <a-checkbox :defaultChecked="true" :checked="checkedData.sfz" />
            </span>
            <span class="legend-item" @click="legendClick('wc')" style="margin-left: 12px;">
                <img style="margin-left: 8px;" src="../../assets/images/map/icon_map_sxj_wc_online.png"> 外场
                <a-checkbox :defaultChecked="true" :checked="checkedData.wc" />
            </span>
        </div>
        <div class="img-wrapper">
            <span class="legend-item" @click="legendClick('mj')">
                <img src="../../assets/images/map/icon_map_sxj_mj_online.png"> 门架
                <a-checkbox :defaultChecked="true" :checked="checkedData.mj" />
            </span>
            <span class="legend-item" @click="legendClick('fwq')" style="margin-left: 12px;">
                <img style="margin-left: 8px;" src="../../assets/images/map/icon_map_sxj_fwq_online.png"> 服务区
                <a-checkbox :defaultChecked="true" :checked="checkedData.fwq" />
            </span>
        </div>
    </div>
</template>

<script>

    import Corner from '../../assets/images/corner'

    export default {
        name: "MonitorMapCameraLegend",
        data() {
            return {
                Corner,
                checkedData: {
                    sd: true,
                    sfz: true,
                    wc: true,
                    mj: true,
                    fwq: true
                }
            }
        },
        methods: {
            panelHide() { // 隐藏面板
                this.$refs.legendPanel.style.left = '228px'
            },
            panelShow() { // 显示面板
                this.$refs.legendPanel.style.left = '540px'
            },
            legendClick (type) { // 点击图层,切换相机可见状态
                this.checkedData[type] = !this.checkedData[type]
                this.$emit('legendSelectClick', this.checkedData)
            },
            checkedAll () { // 选中全部
                this.checkedData = {
                    sd: true,
                    sfz: true,
                    wc: true,
                    mj: true,
                    fwq: true
                }

            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$eventBus.$on('showLeftPanel', (visible) => {
                    if (visible) {
                        this.panelShow()
                    } else {
                        this.panelHide()
                    }
                })
            })
        }
    }
</script>

<style lang="less" scoped>

    .panel-corner {
        & img:nth-child(1) {
            position: absolute;
            top: -1px;
            left: -1px;
        }

        & img:nth-child(2) {
            position: absolute;
            right: -1px;
            top: -1px;
        }

        & img:nth-child(3) {
            position: absolute;
            bottom: -1px;
            left: -1px;
        }

        & img:nth-child(4) {
            position: absolute;
            right: -1px;
            bottom: -1px;
        }
    }

    .map-layer-control {
        height: 66px;
        padding: 8px;
        padding-bottom: 0px;
        position: absolute;
        left: 540px;
        bottom: 25px;
        z-index: 10;
        background-color: rgba(21, 23, 97, 0.85);
        transition: left 0.6s;

        .img-wrapper {
            height: 50%;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.85);
            opacity: 1;
            cursor: default;

            .legend-item {
                cursor: pointer;
            }
        }
    }
</style>
