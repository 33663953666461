<!--视频事件直播-->
<template>
  <div>
    <a-modal
            class="event-video-modal"
            :destroyOnClose="true"
            :title="type === 1 ? '直播画面' : '回放画面'"
            :width="1200"
            v-model="visible"
            :footer="null"
            :maskClosable="MODAL.MASK_CLOSABLE"
            :keyboard="MODAL.KEYBOARD"
            @cancel="close"
    >
<!--      <template slot="footer">-->
<!--        <a-button type="primary" key="ok"  @click="close">关闭</a-button>-->
<!--      </template>-->

      <div class="title">
        {{title}}
      </div>

      <div v-if="type === 1" style="height: 665px; width: 1184px;" id="cameraVideoPlay"/>
      <video
              v-else-if="type === 2"
              :src="videoSrc"
              controls
              width="100%"
              height="100%"
              autoplay="autoplay"
      />
    </a-modal>
  </div>
</template>

<script>

  import { getCameraVideoUrl } from "../../api/cameraVideo"
  import { MODAL } from '../../core/constant'
  import { VIDEO_EVENT_ENUM } from '../../core/enums'

export default {
  name: "EventVideoModal",
  data() {
    return {
      MODAL,
      VIDEO_EVENT_ENUM,
      player:{},
      videoSrc: null,
      eventData: null,
      type: 1, // 1:直播  2:录像
      visible: false,
    }
  },
  computed: {
    title () {
      if (this.eventData !== null) {
        if (this.type === 1) {
          return this.eventData.deviceName
        } else if (this.type === 2) {
          if (this.eventData.alertType !== null && this.eventData.alertType !== '' && this.eventData.alertType !== undefined) {
            return VIDEO_EVENT_ENUM.alertType[this.eventData.alertType] + '（' + this.eventData.deviceName + '）' + this.$moment(this.eventData.alertStartTime).format('YYYY-MM-DD HH:mm:ss')
          } else {
            return '-（' + this.eventData.deviceName + '）' + this.$moment(this.eventData.alertStartTime).format('YYYY-MM-DD HH:mm:ss')
          }
        }
      }
      return '-'
    }
  },
  methods: {
      aliyunPlayer (playUrl) {
          // eslint-disable-next-line no-undef
          this.player = new Aliplayer({
                  "id": 'cameraVideoPlay',
                  "source": playUrl,
                  // "width": "320px",
                  // "height": "180px",
                  // "videoWidth":"320px",
                  // "videoHeight":"180px",
                  "autoplay": true,
                  "isLive": true,
                  "rePlay": false,
                  "showBuffer": false,
                  "snapshot": true,
                  "playsinline": true,
                  "language": "zh-cn",
                  "enableStashBufferForFlv": true,
                  "preload": true,
                  "useH5Prism": true,
                  "liveRetry": 5,
                  "waitingTimeout": 3,
                  "controlBarVisibility": "hover",
                  "skinLayout": [
                      {"name": "bigPlayButton","align": "blabs","x": 30,"y": 80},
                      {"name": "liveDisplay","align": "blabs","x": 15, "y": -8},
                      {"name": "errorDisplay","align": "tlabs","x": 0,"y": 0},
                      {"name": "infoDisplay","align": "cc"},
                      {
                          name: "H5Loading", align: "cc"
                      }
                  ]
              },function(player) {
                  player.play();
              }
          )
          this.player.on('error',function(){
              let errDom = document.getElementsByClassName('prism-ErrorMessage')
              errDom[0].innerHTML = '<div style="color: #fff;font-size: 18px;text-align: center;margin-top: 80px;">播放失败</div>'
          });
      },
      aliyunFullScreen(){
          //全屏实现
          this.player.fullscreenService.requestFullScreen()
      },
      getCameraPlayerUrl(camera){

              this.dispose()
              // let cameraNum = '3243124324312413'
              let param=  {
                  videoType: 0,
                  cameraNum: camera.cameraNum
              }
              getCameraVideoUrl(param).then(res=>{
                  let playUrl = res.data.videoRequestUrl[0].hls_url
                  this.videoData = camera
                  this.aliyunPlayer(playUrl);
              }).catch(err=>{
                  console.log(err)
                  let errUrl = 'https://spell.gstscc.cn/live/stream01.m3u8?auth_key=1607167862-0-0-f9cb9eed814bf510288e2c4e454283ed'
                  this.aliyunPlayer(errUrl)
              })
      },
      dispose () { // 断开流
          if (this.player.dispose !== undefined) {
              this.player.dispose() // 先关闭之前的再播放新的
          }
      },
    show (camera, type) {
      this.eventData = camera
      this.visible = true
        this.$nextTick(() => {
          this.type = type
          if (type === 1) {
            this.getCameraPlayerUrl(camera)
          } else if (type === 2) {
            this.videoSrc = camera.alertVideoStorePath
          }
        })
    },
    close () {
      this.visible = false
      this.dispose()
    }
  }
}
</script>
<style lang="less" scoped>

  .event-video-modal {
    & /deep/ .ant-modal-header {
      display: none;
    }
    & /deep/ .ant-modal-body {
      padding: 8px;
    }

    .title {
      width: 1184px;
      height: 37px;
      line-height: 37px;
      background: #3740C5;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      padding-left: 12px;
    }
  }

    .event-video-modal /deep/ video {
        object-fit: fill !important;
    }

</style>
