<template>
    <div class="map-layer-control" ref="controlPanel">
        <!--    <a-row>-->
        <!--      <a-col :span="4" style="cursor: pointer;" @click="sxjLayerSelectClick">-->
        <!--        <img v-if="sxjLayerSelect" src="../../assets/images/map/layerCtrl/btn_layercontrol_sxj_unselected.png">-->
        <!--        <img v-else src="../../assets/images/map/layerCtrl/switch_layer_sxj_on.png">-->
        <!--      </a-col>-->
        <!--      <a-col :span="4" style=" cursor: pointer; border-left: 1px solid  #0088FF;" @click="etcLayerSelectClick">-->
        <!--        <img v-if="etcLayerSelect" src="../../assets/images/map/layerCtrl/btn_layercontrol_etcmj_unselected.png">-->
        <!--        <img v-else src="../../assets/images/map/layerCtrl/switch_layer_etcmj_on.png">-->
        <!--      </a-col>-->
        <!--      <a-col :span="4" style="cursor: pointer;  border-left: 1px solid  #0088FF;" @click="sfzLayerSelectClick">-->
        <!--        <img v-if="sfzLayerSelect" src="../../assets/images/map/layerCtrl/btn_layercontrol_sfz_unselected.png">-->
        <!--        <img v-else src="../../assets/images/map/layerCtrl/switch_layer_sfz_on.png">-->
        <!--      </a-col>-->
        <!--      <a-col :span="12" style="cursor: pointer;  border-left: 1px solid  #0088FF;text-align: left" @click="trafficSelectClick">-->
        <!--        <img v-if="trafficSelect" src="../../assets/images/map/layerCtrl/btn_layercontrol_lk_unselected.png">-->
        <!--        <img v-else src="../../assets/images/map/layerCtrl/traffic_on.png">-->
        <!--      </a-col>-->
        <!--    </a-row>-->

<!--        <img class="sxj-selected" v-if="sxjLayerSelect"-->
<!--             src="../../assets/images/map/layerCtrl/btn_layercontrol_sxj_selected.png" @click="sxjLayerSelectClick">-->
<!--        <img class="sxj-unselected" v-else src="../../assets/images/map/layerCtrl/btn_layercontrol_sxj_unselected.png"-->
<!--             @click="sxjLayerSelectClick">-->

<!--        <img class="sfz-selected" v-if="sfzLayerSelect"-->
<!--             src="../../assets/images/map/layerCtrl/btn_layercontrol_sfz_selected.png" @click="sfzLayerSelectClick">-->
<!--        <img class="sfz-unselected" v-else src="../../assets/images/map/layerCtrl/btn_layercontrol_sfz_unselected.png"-->
<!--             @click="sfzLayerSelectClick">-->

<!--        <img class="etc-selected" v-if="etcLayerSelect"-->
<!--             src="../../assets/images/map/layerCtrl/btn_layercontrol_etcmj_selected.png" @click="etcLayerSelectClick">-->
<!--        <img class="etc-unselected" v-else src="../../assets/images/map/layerCtrl/btn_layercontrol_etcmj_unselected.png"-->
<!--             @click="etcLayerSelectClick">-->

        <img class="sj-selected" v-if="sjLayerSelect"
             src="../../assets/images/map/layerCtrl/btn_layercontrol_sj_selected.png" @click="sjLayerSelectClick">
        <img class="sj-unselected" v-else src="../../assets/images/map/layerCtrl/btn_layercontrol_sj_unselected.png"
             @click="sjLayerSelectClick">

        <img class="spsj-selected" v-if="spsjLayerSelect"
             src="../../assets/images/map/layerCtrl/btn_layercontrol_spsj_selected.png" @click="spsjLayerSelectClick">
        <img class="spsj-unselected" v-else src="../../assets/images/map/layerCtrl/btn_layercontrol_spsj_unselected.png"
             @click="spsjLayerSelectClick">

        <img class="lk-selected" v-if="trafficSelect"
             src="../../assets/images/map/layerCtrl/btn_layercontrol_lk_selected.png" @click="trafficSelectClick">
        <img class="lk-unselected" v-else src="../../assets/images/map/layerCtrl/btn_layercontrol_lk_unselected.png"
             @click="trafficSelectClick">

    </div>
</template>

<script>
    export default {
        name: "MonitorMapLayerControl",
        data() {
            return {
                sxjLayerSelect: true, // 摄像机图层
                etcLayerSelect: false, // etc图层
                sfzLayerSelect: false, // 收费站图层
                sjLayerSelect: true, // 高德事件图层
                spsjLayerSelect: true, // 视频事件图层
                trafficSelect: true // 路况图层
            }
        },
        methods: {
            sxjLayerSelectClick() {
                this.sxjLayerSelect = !this.sxjLayerSelect
                let layerSelectOptions = {
                    layerType: 'camera',
                    selected: this.sxjLayerSelect
                }
                this.$emit('layerSelectClick', layerSelectOptions)
            },
            etcLayerSelectClick() {
                this.etcLayerSelect = !this.etcLayerSelect
                let layerSelectOptions = {
                    layerType: 'etc',
                    selected: this.etcLayerSelect
                }
                this.$emit('layerSelectClick', layerSelectOptions)
            },
            sjLayerSelectClick() {
                this.sjLayerSelect = !this.sjLayerSelect
                let layerSelectOptions = {
                    layerType: 'event',
                    selected: this.sjLayerSelect
                }
                this.$emit('layerSelectClick', layerSelectOptions)
            },
            spsjLayerSelectClick() {
                this.spsjLayerSelect = !this.spsjLayerSelect
                let layerSelectOptions = {
                    layerType: 'videoEvent',
                    selected: this.spsjLayerSelect
                }
                this.$emit('layerSelectClick', layerSelectOptions)
            },
            sfzLayerSelectClick() {
                this.sfzLayerSelect = !this.sfzLayerSelect
                let layerSelectOptions = {
                    layerType: 'tollStation',
                    selected: this.sfzLayerSelect
                }
                this.$emit('layerSelectClick', layerSelectOptions)
            },
            trafficSelectClick() {
                this.trafficSelect = !this.trafficSelect
                let layerSelectOptions = {
                    layerType: 'traffic',
                    selected: this.trafficSelect
                }
                this.$emit('layerSelectClick', layerSelectOptions)
            },
            panelHide() { // 隐藏面板
                this.$refs.controlPanel.style.left = '12px'
            },
            panelShow() { // 显示面板
                this.$refs.controlPanel.style.left = '320px'
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.$eventBus.$on('showLeftPanel', (visible) => {
                    if (visible) {
                        this.panelShow()
                    } else {
                        this.panelHide()
                    }
                })
            })
        }
    }
</script>

<style lang="less" scoped>
    .map-layer-control {
        width: 202px;
        height: 66px;
        position: absolute;
        padding-left: 46px;
        left: 320px;
        bottom: 25px;
        z-index: 10;
        line-height: 64px;
        text-align: center;
        background-image: url("../../assets/images/map/layerCtrl/bg_layercontrol.png");
        /*background-image: url("../../assets/images/map/layerCtrl/bg_layercontrol_tmp.png");*/
        background-repeat: no-repeat;
        transition: left 0.6s;

        img:hover {
            cursor: pointer;
        }

        /*图层图标hover效果*/

        .sxj-selected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_sxj_selectedhover.png");
        }

        .sxj-unselected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_sxj_unselectedhover.png");
        }

        .sfz-selected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_sfz_selectedhover.png");
        }

        .sfz-unselected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_sfz_unselectedhover.png");
        }

        .etc-selected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_etcmj_selectedhover.png");
        }

        .etc-unselected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_etcmj_unselectedhover.png");
        }

        .lk-selected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_lk_selectedhover.png");
        }

        .lk-unselected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_lk_unselectedhover.png");
        }

        .sj-selected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_sj_selectedhover.png");
        }

        .sj-unselected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_sj_unselectedhover.png");
        }

        .spsj-selected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_spsj_selectedhover.png");
        }

        .spsj-unselected:hover {
            content: url("../../assets/images/map/layerCtrl/btn_layercontrol_spsj_unselectedhover.png");
        }
    }
</style>
