<!--高德事件-->
<template>
  <div>
    <div class="event-dialog" @click="eventDialogClick">
      <span class="close-icon" @click="close($event)">×</span>
<!--      <div class="event-ioi">{{eventData.ioi_name}}</div>-->
<!--      <div class="event-name">{{EVENT_TYPE.properties[eventData.sub_evt_type_no].label}}</div>-->

      <div class="event-dialog-line">
        <div class="event-dialog-icon">
          <img :src="EVENT_TYPE.properties[eventData.sub_evt_type_no].src">
        </div>
        <div >
          <span class="event-dialog-road">{{eventData['road_code']}}</span>
          <span class="event-dialog-type">{{EVENT_TYPE.properties[eventData.sub_evt_type_no].label}}</span>
        </div>
        <div class="event-dialog-time">{{eventData.start_time}}</div>
      </div>

      <div class="event-dialog-line" style="margin-top: 4px;">
        <div style="float: left; margin-top: -3px;">
          <img src="../../assets/images/iconfont/icon_pop_position.png">
        </div>
        <div class="event-dialog-detail">
          {{eventData['start_ioi_name']}}
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import { EVENT_TYPE } from '../../core/enums'

export default {
  name: "MonitorEventDialog",
  props: {
    eventData: {
      type: Object,
      default: () => {
        return {
          'ioi_name': '',
          'evt_name': ''
        }
      }
    }
  },
  data() {
    return {
      EVENT_TYPE
    };
  },
  methods: {
    eventDialogClick () { // 点击气泡对话框,切换右下角事件面板内容
      this.$emit('changeEventData', this.eventData)
    },
    close (dom) {
      dom.stopPropagation()
      dom.target.parentNode.remove()
    }
  }
}
</script>
<style lang="less" scoped>

  .event-dialog {
    width: 210px;
    height: 106px;
    background-color: rgba(21, 23, 97, 1);
    /*position: absolute;*/
    /*top: 160px;*/
    /*left: 360px;*/
    /*margin-bottom: 60px;*/
    border-radius: 10px;
    border: 2px solid rgba(1, 176, 209, 1);
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.85);
    padding: 10px;
    cursor: pointer;

    .close-icon {
      position: absolute;
      font-size: 16px;
      top: 1px;
      right: 10px;
      color: #0088FF;
      font-weight: bold;
    }

    .event-ioi {
      font-weight: bold;
    }

    .event-name {
      margin-top: 8px;
    }

    .event-dialog-line {
      height: 40px;
    }

    .event-dialog-icon {
      float: left;
      margin-right: 4px;
    }
    .event-dialog-type {
      margin-left: 4px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #00CBFF;
      bottom: 1px;
      position: relative;
    }
    .event-dialog-time {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
    }
    .event-dialog-road {
      float: left;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #00CBFF;
      border: 1px solid rgba(0, 203, 255, 0.45);
      border-radius: 6px;
      padding-left: 4px;
      padding-right: 4px;
    }
    .event-dialog-detail {
      float: left;
      width: 85%;
      margin-left: 4px;
      font-size: 13px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #00CBFF;
      opacity: 0.65;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

  }
  .event-dialog:before {
    content: "";
    position: absolute;
    top: 106px;
    left: 108px;
    border-top: 70px solid rgba(1, 176, 209, 1);
    border-right: 10px solid transparent;
    /*border-bottom: 16px solid transparent;*/
    border-left: 10px solid transparent;
  }
  .event-dialog:after {
    content: "";
    position: absolute;
    top: 103px;
    left: 110px;
    border-top: 66px solid rgba(21, 23, 97, 1);
    border-right: 8px solid transparent;
    /*border-bottom: 14px solid transparent;*/
    border-left: 8px solid transparent;
  }

</style>
