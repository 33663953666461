<!--面板的标题-->
<template>
  <div class="panel-title">

    <div class="line-left" ref="domTitle">{{title}}</div>
    <div class="line-middle"></div>
    <div class="line-right" :style="{'width': lineRightWidth}">
      <img class="line-right-top" :style="{'width': lineRightWidth}" src="../../assets/images/title/bg_cardtitle_3.png">
      <div class="line-right-bottom"></div>
    </div>

      <!--操作栏,最小化按钮-->
      <img class="visible-button visible-button-hide" @click="hidePanel" v-if="toolbar > 0" src="../../assets/images/iconfont/btn_closewindow_normal.png">

  </div>
</template>

<script>

// let isClick = false

export default {
    name: 'PanelTitle',
    computed: {
      lineRightWidth () { // 计算标题线的长度,没啥逻辑

        let lineRightWidth = 0
        let lineLeftWidth = 16 * this.title.length // 通过字数 * 字号算出宽度
        let lineMiddleWidth = 41
        let padding = 36
        lineRightWidth = this.panelWidth - padding - lineLeftWidth - lineMiddleWidth
        if (this.toolbar > 0) { // 有操作按钮,短一些
          lineRightWidth -= (24 * this.toolbar)
        }
        return lineRightWidth + 'px'
      }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        toolbar: { // 顶部操作按钮个数,方便计算横线效果的长度
          type: Number,
          default: 0
        },
        panelDom: {}, // 将
        panelWidth: { // 面板宽度
          type: Number,
          default: 290
        }
    },
    methods: {
        hidePanel () { //隐藏面板
          this.$emit('hidePanel')
          // if(!isClick) { // 防止疯狂点击出事
          //   this.panelDom.classList.remove('flipInY')
          //   this.panelDom.classList.add('fadeOut')
          //   isClick = true
          //   // this.panelDom.style.opacity = 0
          //   setTimeout(() => {
          //     this.panelDom.style.visibility = 'hidden'
          //     isClick = false
          //   }, 500)
          //
          //   // 隐藏父组件中的展开按钮
          //   this.$emit('showVisibleButton', true)
          // }
        },
        showPanel () { // 显示面板
          this.$emit('showPanel')
            // isClick = false
            // this.panelDom.classList.remove('fadeOut')
            // this.panelDom.classList.add('fadeIn')
            //
            // this.panelDom.style.visibility = 'visible'
            // // this.panelDom.style.opacity = 1
            //
            // if (type === 'online') {
            //   this.$eventBus.$emit('eventPanelExpand', false)
            // } else if (type === 'event') {
            //   // console.log()
            // }
            //
            // // 隐藏父组件中的展开按钮
            // this.$emit('showVisibleButton', false)
        },
    }
}
</script>

<style lang="less" scoped>

  .panel-title {
    margin-top: 16px;
    padding-left: 16px;
    height: 32px;
    width: 100%;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: #00CBFF;
    opacity: 1;
    display: inline-flex;
    overflow: hidden;

    .line-left {
      background-image: url("../../assets/images/title/bg_cardtitle_1.png");
      background-repeat: no-repeat;
      height: 100%;
      float: left;
      white-space: nowrap;
    }

    .line-middle {
      background-image: url("../../assets/images/title/bg_cardtitle_2.png");
      background-repeat: no-repeat;
      width: 41px;
      height: 100%;
      float: left;
    }

    .line-right {
      float: left;
      height: 100%;
      position: relative;
      /*width: 90px;*/
      /*text-align: right;*/
      transition: width .5s;

      .line-right-top {
        /*background-image: url("../../assets/images/title/bg_cardtitle_3.png");*/
        /*background-repeat: no-repeat;*/
        height: 12px;
        position: absolute;
        left: 0px;
        transition: width .5s;
      }
      .line-right-bottom {
        background-image: url("../../assets/images/title/bg_cardtitle_4.png");
        background-repeat: no-repeat;
        height: 20px;
        width: 57px;
        position: absolute;
        right: 0px;
        top: 12px;
      }
    }
  }

  .visible-button {
      position: absolute;
      right: 14px;
      top: 14px;
      cursor: pointer;
  }

  .visible-button-hide {
      &:hover {
          content: url("../../assets/images/iconfont/btn_closewindow_hover.png");
      }
  }

</style>
