<!--高德事件-->
<template>
  <div>
    <a-modal
            :destroyOnClose="true"
            :title="title"
            :width="MODAL.WIDTH"
            v-model="visible"
            :maskClosable="MODAL.MASK_CLOSABLE"
            :keyboard="MODAL.KEYBOARD"
    >
      <template slot="footer">
        <a-button type="primary" key="ok"  @click="close">关闭</a-button>
      </template>

      <a-table
              :columns="columns"
              :data-source="eventDatas"
              :pagination="true"
              :loading="loading"
              @change="handleTableChange"
              :row-key="record => record.alert_id"
              :locale="{emptyText: '暂无数据'}"
      >
        <span slot="road_code" slot-scope="text">
             {{text !== '' ? HIGHWAY_ENUM.properties[text].label : '-'}}
         </span>
         <span slot="evt_type" slot-scope="text">
<!--             <img :src="EVENT_TYPE.properties[text].src">-->
             {{EVENT_TYPE.properties[text].label}}
         </span>
          <span slot="start_ioi_name" slot-scope="text">
             {{text | emptyFilter}}
         </span>
      </a-table>

    </a-modal>
  </div>
</template>

<script>

  // import { getEventFusionList } from '../../api/event'
  import { MODAL } from '../../core/constant'
  import { EVENT_TYPE, HIGHWAY_ENUM, EVENT_STATUS } from '../../core/enums'
  import Empty from '../../components/common/Empty'

const columns = [
  {
    title: '序号',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '路线',
    dataIndex: 'road_code',
    key: 'road_code',
    scopedSlots: { customRender: 'road_code' }
  },
  {
    title: '类型',
    dataIndex: 'sub_evt_type_no',
    key: 'sub_evt_type_no',
    scopedSlots: { customRender: 'evt_type' }
  },
  {
    title: '位置',
    dataIndex: 'start_ioi_name',
    key: 'start_ioi_name',
    scopedSlots: { customRender: 'start_ioi_name' }
  },
  {
    title: '开始时间',
    dataIndex: 'start_time',
    key: 'start_time'
  },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        customRender: (text) => {return EVENT_STATUS.properties[text].label}
    }
]

export default {
  name: "MonitorEventHistoryModal",
  components: {Empty},
  data() {
    return {
      MODAL,
      EVENT_TYPE,
      HIGHWAY_ENUM,
      EVENT_STATUS,
      title: '交通事件历史',
      visible: false,
      eventDatas: [],
      loading: false,
      columns: columns
    };
  },
  methods: {
    show (eventDatas) {
      this.visible = true
      this.eventDatas = eventDatas
    },
    close () {
      this.visible = false
    },
    handleTableChange () {
        // this.loading = true
        // setTimeout(() => {
        //     this.loading = false
        // }, 200)
    }
  },
  mounted() {
    this.$nextTick(() => {

    })
  }
}
</script>
<style lang="less" scoped>


</style>
