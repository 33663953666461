<!--视频事件历史-->
<template>
    <div>
        <a-modal
                :destroyOnClose="true"
                :title="title"
                :width="MODAL.WIDTH"
                v-model="visible"
                :maskClosable="MODAL.MASK_CLOSABLE"
                :keyboard="MODAL.KEYBOARD"
        >
            <template slot="footer">
                <a-button type="primary" key="ok" @click="close">关闭</a-button>
            </template>

            <div>

                <a-row>
                    <a-col span="12" style="padding-bottom: 12px;">
                        <span class="item-label">报警时间：</span>
                        <a-range-picker
                                :show-time="{ format: 'HH:mm' }"
                                format="YYYY-MM-DD HH:mm"
                                :placeholder="['开始时间', '结束时间']"
                                :allowClear="false"
                                @change="onChange"
                                @ok="onOk"
                                :value="queryDate"
                        />
                    </a-col>

                    <a-col span="12" style="padding-bottom: 12px;">
                        <span class="item-label">报警类型：</span>
                        <a-select style="width: 208px;" v-model="alertType" @change="handleTypeChange">
                            <a-select-option :value="''">
                                全部
                            </a-select-option>
                            <a-select-option :value=item.leve3 v-for="(item, index) in eventTypes" :key="index">
                                {{item.resourceName}}
                            </a-select-option>
                        </a-select>
                    </a-col>

                    <a-col span="12" style="padding-bottom: 12px;">
                        <span class="item-label">所属单位：</span>
                        <a-input style="width: 208px;" v-model="department" placeholder="请输入相机所属单位" />
                    </a-col>
                    <a-col span="12" style="padding-bottom: 12px;">
                        <span class="item-label">相机名称：</span>
                        <a-input style="width: 208px;" v-model="deviceName" placeholder="请输入相机名称" />
                    </a-col>

                    <a-col span="12" style="padding-bottom: 12px;">
                        <span class="item-label">是否报警：</span>
                        <a-select style="width: 208px;" v-model="isAlarm" @change="handleIsAlarmChange">
                            <a-select-option :value="-1">
                                全部
                            </a-select-option>
                            <a-select-option :value="1">
                                是
                            </a-select-option>
                            <a-select-option :value="0">
                                否
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col span="12" v-if="isAlarm === 1">
                        <span class="item-label">是否确认：</span>
                        <a-select style="width: 208px;" v-model="isConfirm">
                            <a-select-option :value="-1">
                                全部
                            </a-select-option>
                            <a-select-option :value="1">
                                已确认
                            </a-select-option>
                            <a-select-option :value="0">
                                未确认
                            </a-select-option>
                        </a-select>
                    </a-col>
                </a-row>

                <div style="text-align: right;">
                    <a-button type="primary" style="margin-left: 16px;" @click="handleQuery">确定</a-button>
                    <a-button style="margin-left: 8px;" @click="reset">重置</a-button>
                </div>
            </div>

            <a-table
                    style="margin-top: 24px;"
                    :columns="columns"
                    :data-source="eventData"
                    :pagination="pagination"
                    :loading="loading"
                    @change="handleTableChange"
                    :row-key="record => record.id"
                    :locale="{emptyText: '暂无数据'}"
            >

         <span slot="index" slot-scope="text, record, index">
             {{(pagination.current - 1) * pagination.pageSize + index + 1}}
         </span>

        <span slot="alertType" slot-scope="text">
             {{VIDEO_EVENT_ENUM.alertType[text]}}
         </span>

         <span slot="alertStartTime" slot-scope="text">
             {{$moment(text).format('YYYY-MM-DD HH:mm:ss')}}
         </span>

         <span slot="alertEndTime" slot-scope="text">
             {{$moment(text).format('YYYY-MM-DD HH:mm:ss')}}
         </span>

                <span
                        slot="operation"
                        slot-scope="text, record"
                >
            <a style="margin-left: 8px" @click="detail(record)">详情</a>
            <a style="margin-left: 8px" @click="liveVideo(record)">查看直播</a>
            <a style="margin-left: 8px" @click="historyVideo(record)">查看回放</a>
          </span>
            </a-table>

            <video-event-detail-modal ref="videoEventDetailModal" @refreshData="refreshData" />
            <event-video-modal ref="eventVideoModal"></event-video-modal>
        </a-modal>


    </div>
</template>

<script>

    // import { getEventFusionList } from '../../api/event'
    import Vue from 'vue'
    import {MODAL} from '../../core/constant'
    import {VIDEO_EVENT_ENUM} from '../../core/enums'
    import Empty from '../../components/common/Empty'
    import {getAnalyseData} from '../../api/cameraAnalyse'
    // import { listAllEventType } from '../../api/eventType'
    import { listResource } from '../../api/resource'
    import EventVideoModal from './EventVideoModal'
    import VideoEventDetailModal from "./VideoEventDetailModal";

    const columns = [
        {
            title: '序号',
            scopedSlots: {customRender: 'index'},
            // customRender: (text, record, index) => `${index + 1}`
        },
        {
            title: '摄像机名称',
            dataIndex: 'deviceName',
            key: 'deviceName'
        },
        {
            title: '报警类型',
            dataIndex: 'alertType',
            key: 'alertType',
            scopedSlots: {customRender: 'alertType'}
        },
        {
            title: '报警时间',
            dataIndex: 'alertStartTime',
            key: 'alertStartTime',
            scopedSlots: {customRender: 'alertStartTime'}
        },
        {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            scopedSlots: {customRender: 'operation'}
        }
    ]

    export default {
        name: "MonitorVideoEventHistoryModal",
        components: {VideoEventDetailModal, EventVideoModal, Empty},
        data() {
            return {
                MODAL,
                VIDEO_EVENT_ENUM,
                title: '视频事件历史',
                visible: false,
                eventTypes: [], // 事件类型
                eventData: [],
                loading: false,
                queryDate: [],
                columns: columns,
                alertType: '',
                department: '',
                deviceName: '',
                isAlarm: -1,
                isConfirm: -1,
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0
                }
            };
        },
        methods: {
            show() {
                this.visible = true
                this.reset()
                this.setDefaultDate()
                this.$nextTick(() => {
                    this.query() // 默认查询1小时内数据
                })
            },
            close() {
                this.visible = false
                this.eventData = []
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination }
                pager.current = pagination.current
                pager.pageSize = pagination.pageSize
                this.pagination = pager
                this.query()
            },
            handleTypeChange (type) {
                this.alertType = type
            },
            queryEventTypeData () {
                const roleId = Vue.ls.get('user').roleIds[0] // 角色id
                listResource(roleId).then(res => {
                    this.eventTypes = res.data.filter(item => item.leve1 === 'event' && item.leve3 !== null)
                })
            },
            handleQuery () {
                this.pagination.current = 1
                this.query()
            },
            query() {
                if (this.queryDate.length === 0) {
                    this.setDefaultDate()
                }
                let param = {
                    department: this.department,
                    alertType: this.alertType,
                    startTime: this.$moment(this.queryDate[0]).format('YYYY-MM-DD HH:mm:ss'),
                    endTime: this.$moment(this.queryDate[1]).format('YYYY-MM-DD HH:mm:ss'),
                    deviceName: this.deviceName,
                    fromCompany: '',
                    isAlarm: this.isAlarm,
                    isConfirm: this.isConfirm,
                    page: this.pagination.current,
                    'page-size': this.pagination.pageSize,
                }
                // 查询事件实时数据
                this.loading = true
                getAnalyseData(param).then(res => {
                    this.eventData = res.data.data
                    this.pagination.total = res.data.total
                }).finally(() => {
                    this.loading = false
                })
            },
            setDefaultDate () { // 默认查询时间--当天00:00~至今
                // this.queryDate[0] = this.$moment(new Date()).subtract(1, 'hours')
                this.queryDate[0] = this.$moment().startOf('day') // 默认查当天0点开始
                this.queryDate[1] = this.$moment(new Date())
            },
            reset () {
                this.queryDate = []
                this.alertType = ''
                this.department = ''
                this.deviceName = ''
                this.isAlarm = -1
                this.isConfirm = -1
            },
            detail(record) {
                this.$refs.videoEventDetailModal.open(record)
            },
            liveVideo (record) {
                this.$refs.eventVideoModal.show(record, 1) // 实时视频
            },
            historyVideo (record) {
                this.$refs.eventVideoModal.show(record, 2) // 录像
            },
            onChange(value) {
                this.queryDate = value
                // this.queryDate[0] = value[0]
                // this.queryDate[1] = value[1]
            },
            onOk(value) {
                this.queryDate = value
            },
            handleIsAlarmChange (value) {
                if (value === -1 || value === 0) {
                    this.isConfirm = -1
                }
            },
            refreshData () {
                this.query()
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.queryEventTypeData()
            })
        }
    }
</script>
<style lang="less" >

    input::-webkit-input-placeholder {
        color: #0d5699 !important;
    }
    input:-moz-placeholder {
        color: #0d5699;
    }
    input::-moz-placeholder {
        color: #0d5699;
    }
    input:-ms-input-placeholder {
        color: #0d5699;
    }

    .item-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0088FF;
    }


</style>
