<!--资源面板资源页面-->
<template>
  <div>
    <div class="highway-list">
      <div class="example" v-if="isRoadDataSpinning">
        <a-spin :spinning="isRoadDataSpinning" />
      </div>
      <div v-for="(item, index) of classify.data" :key="item.value" style="cursor: pointer;" >
        <a-row :id="index">
          <a-col :span="24">
            <div @click="resourceClick(item)">
              <a-row>
                <a-col :span="19">
                  <div class="highway-name-text">
                    <span class="resource-index">{{index + 1}}</span>
                    <span class="resource-label">{{item.label.replace('项目管理有限公司', '')}}</span>
                  </div>
                </a-col>
                <a-col :span="5">
                  <a-tooltip placement="top">
                    <template slot="title">
                      在线数 / 总接入数
                    </template>
                    <div class="highway-online-sum-text">
                      {{item.cameraOnlineTotal}}/{{item.cameraTotal}}
                    </div>
                  </a-tooltip>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <div>
                    <a-progress :percent="item.percentage" />
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
      </div>

    </div>
  </div>
</template>

<script>
export default {
name: "MonitorResourceItem",
  props: {
    classify: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return{
      isRoadDataSpinning: false
    }
  },
  methods:{
    resourceClick(resource){
      this.$emit('resourceClick', this.classify, resource)
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.highway-list{

}
.highway-list /deep/ .example {
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 300px 0;
}
.highway-list /deep/.highway-name-text{
  height: 22px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.85);
  opacity: 1;
}
.highway-list /deep/ .highway-online-sum-text{
  /*width: 77px;*/
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  opacity: 1;
  margin-left: -15px;
}
.highway-list /deep/ .ant-progress-outer {
  padding-right: 0px;
  margin-right: 0px;
}
.highway-list /deep/ .ant-progress-text {
  visibility:hidden ;
}
.highway-list /deep/ .ant-progress-bg{
  background-color: #0088FF;
}
.highway-list  /deep/ .ant-progress-inner{
  background-color: #383B76;
}
.highway-list /deep/ .anticon {
  visibility: hidden;
}
.highway-list /deep/ .ant-progress-status-success .ant-progress-bg {
  background-color: #0088FF;
}

.resource-index {
  font-size: 16px;
  font-weight: bold;
  color: #0088ff;
}

.resource-label {
  margin-left: 12px;
  white-space: nowrap
}

</style>
