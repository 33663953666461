import { get } from '@/utils/request'
const URL = 'manage/camera/total/'

// 查询高速公路统计
export const getCameraTotal= roleId => get(URL + roleId)

// 临时接口-新接路段功能后的高速公路
export const getCameraTotalTemp= roleId => get(URL + '/temp/' + roleId)
// 临时接口-新接路段功能后的新接路段
export const getCameraTotalNewRoadTemp= roleId => get(URL + '/temp/newRoad/' + roleId)

// 临时接口-查询国省干线统计
export const getBureauCameraTotal = p => get(`${URL}/temp/roadBureau/` + p)

// 查询水运统计
export const getWaterCameraTotal = p => get(`${URL}/temp/water/` + p)

// 查询水运统计
export const getPassengerCameraTotal = p => get(`${URL}/passenger/` + p)
