<!--右上的视频故障统计-->
<template>
  <div>
      <a-tooltip placement="left" v-if="showVisibleButtonFlag">
          <template slot="title">
              视频在线数统计
          </template>
          <img class="visible-button visible-button-show" @click="$refs.panelTitle.showPanel('online')" src="../assets/images/iconfont/btn_video_normal.png">
      </a-tooltip>

      <div class="layerControl animated"
           :class="{flipInY: !spinning.highway && !spinning.road && !spinning.water && !spinning.passenger && refreshNum > 1}"
           ref="layerControl"
           :style="{width: panelWidth + 'px'}"
           v-if="resourceData.find(item => item.leve1 === RESOURCE_TYPE.视频在线数统计.value) !== undefined">
<!--      <div class="layerTop"></div>-->
<!--      <div class="layerMiddle"></div>-->
<!--      <div class="layerBottom"></div>-->

      <!--四个边角-->
      <div class="panel-corner">
        <img :src="Corner.BorderLeftTop">
        <img :src="Corner.BorderRightTop">
        <img :src="Corner.BorderLeftBottom">
        <img :src="Corner.BorderRightBottom">
      </div>

<!--        <a-tooltip placement="left">-->
<!--            <template slot="title">-->
<!--                在线数统计 & 事件-->
<!--            </template>-->
<!--            <div class="pie-icon" @click="statisticsPanelVisible">-->
<!--                <div>-->
<!--&lt;!&ndash;                    <img src="../assets/images/iconfont/pie-chart.png" title="高速公路">&ndash;&gt;-->
<!--                    <img :src="arrow" style="">-->
<!--                </div>-->
<!--            </div>-->
<!--        </a-tooltip>-->

      <div v-if="showSwitchButtonFlag && chartCountHighway + (chartCountOthers > 1 ? 1 : 0) > 1" class="switch-button">
          <img class="switch-button-prev" src="../assets/images/iconfont/btn_leftarrow_normal.png" @click="$refs.monitorVideoStatisticsChart.switchChart('prev')">
          <img class="switch-button-next" src="../assets/images/iconfont/btn_rightarrow_normal.png" @click="$refs.monitorVideoStatisticsChart.switchChart('next')">
      </div>

      <div v-if="chartCountHighway + (chartCountOthers > 1 ? 1 : 0) > 1" class="card-show-button" @click="panelExpandClick">
          <img :src="panelExpandIcon">
      </div>

      <a-spin :spinning="(spinning.highway || spinning.road || spinning.water || spinning.passenger) && refreshNum === 1">
          <div class="layerContent">
              <!--          <div class="chart-name-div">视频在线数统计</div>-->
              <panel-title :title="'视频在线数统计'" :toolbar="1" :panelDom="$refs.layerControl" @showVisibleButton="showVisibleButton" @hidePanel="hidePanel" @showPanel="showPanel" :panelWidth="panelWidth" ref="panelTitle"/>
              <!--          <img class="visible-button visible-button-hide" @click="hidePanel" v-if="panelVisible" src="../assets/images/iconfont/btn_closewindow_normal.png">-->

              <!--全部行业的数据都加载完再加载组件,不然会造成数据错乱-->
              <monitor-video-statistics-chart v-if="chartOptions.highway.length > 0 || chartOptions.others.length > 0" :chartOptions="chartOptions" :panelExpand="panelExpand" ref="monitorVideoStatisticsChart" @changeJtbVisible="changeJtbVisible"/>
<!--              <monitor-video-statistics-chart-normal></monitor-video-statistics-chart-normal>-->

              <!--                <monitor-video-statistics-chart v-if="hasHighway && highwayData" :chartOptions="highwayData" :chartWidth="chartWidth" />-->
              <!--                <monitor-video-statistics-chart v-if="hasRoad && roadData" :chartOptions="roadData" :chartWidth="chartWidth" />-->
              <!--                <monitor-video-statistics-chart v-if="hasPassenger && passengerData" :chartOptions="passengerData" :chartWidth="chartWidth" />-->
              <!--                <monitor-video-statistics-chart v-if="hasWater && waterData" :chartOptions="waterData" :chartWidth="chartWidth" />-->


              <!--            <div style="margin-top: 36px;">-->
              <!--                <monitor-video-statistics-chart :type="'highway'" :title="'高速公路'"></monitor-video-statistics-chart>-->
              <!--                <monitor-video-statistics-chart :type="'road'" :title="'国省干线'"></monitor-video-statistics-chart>-->
              <!--                <monitor-video-statistics-chart :type="'water'" :title="'水运'"></monitor-video-statistics-chart>-->
              <!--                <monitor-video-statistics-chart :type="'passenger'" :title="'客运'"></monitor-video-statistics-chart>-->

              <!--                <v-chart v-if="hasHighway"  style="width: 230px;height: 140px" :options="highwayData"></v-chart>-->
              <!--                <v-chart v-if="hasRoad"  style="width: 230px;height: 140px" :options="roadData"></v-chart>-->
              <!--                <v-chart v-if="hasPassenger"  style="width: 230px;height: 140px" :options="passengerData"></v-chart>-->
              <!--                <v-chart v-if="hasWater"  style="width: 230px;height: 140px" :options="waterData"></v-chart>-->
              <!--            </div>-->

<!--              <div v-if="!panelExpand" class="jtb-info" :style="{width: '112px'}">-->
<!--                  <span class="jtb-logo">交通部</span>-->
<!--              </div>-->
<!--              <div v-if="panelExpand" class="jtb-info" :style="{width: '434px'}">-->
<!--                  <span class="jtb-logo">交通部</span>-->
<!--                  <span class="access-info-item-text" style="margin-left: 16px;">总接入数：</span>-->
<!--                  <span class="access-info-item-number">{{totalAccessCount}}</span>-->
<!--                  <span class="access-info-item-text" style="margin-left: 16px;">总接入率：</span>-->
<!--                  <span class="access-info-item-number">{{totalAccessRate}}%</span>-->
<!--              </div>-->


              <div  class="jtb-info" v-if="jtbVisible" :style="{width: panelExpand ? '434px' : '112px', marginLeft: panelExpand ? '34px' : '86px'}">
                  <template v-if="!panelExpand">
                      <span class="jtb-logo">交通部</span>
                  </template>
                  <template v-if="panelExpand">
                      <span class="jtb-logo">交通部</span>
                      <span class="access-info-item-text" style="margin-left: 16px;">总接入数：</span>
                      <span class="access-info-item-number">{{totalAccessCount}}</span>
                      <span class="access-info-item-text" style="margin-left: 16px;">总接入率：</span>
                      <span class="access-info-item-number">{{totalAccessRate}}%</span>
                  </template>
              </div>

          </div>
      </a-spin>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import Corner from '../assets/images/corner'
import ECharts from 'vue-echarts'
// import { getCameraTotal } from '../api/cameraTotal'
import { getCameraTotalTemp, getCameraTotalNewRoadTemp, getBureauCameraTotal, getWaterCameraTotal, getPassengerCameraTotal } from '../api/cameraTotal' // 临时- 新接路段接口
import { listResource } from '../api/resource'
import 'echarts/index'
import MonitorVideoStatisticsChart from "../components/monitor/MonitorVideoStatisticsChart";
import PanelTitle from "../components/common/PanelTitle";
import { deepClone } from "../utils/util"
import { INDUSTRY_TYPE, RESOURCE_TYPE } from '../core/enums'

// let chartData=[
//   {value: 0, name: '正常'},
//   {value: 0, name: '离线'}
// ]

// 高速公路图表的样式
let polarHighway = {
    title: {
        text: '',
        left: 'center',
        textStyle: {
            color: 'rgba(255, 255, 255, 0.85)'
        }
    },
    color: ['#00FFDC','#383B76', '#12145c'],
    legend: {
        selectedMode: false, //取消图例上的点击事件
        align:'left',
        // bottom: '40%',
        icon:'circle',
        // orient 设置布局方式，默认水平布局，可选值：'horizontal'（水平） ¦ 'vertical'（垂直）
        orient: 'vertical',
        // x 设置水平安放位置，默认全图居中，可选值：'center' ¦ 'left' ¦ 'right' ¦ {number}（x坐标，单位px）
        x: 'center',
        // y 设置垂直安放位置，默认全图顶端，可选值：'top' ¦ 'bottom' ¦ 'center' ¦ {number}（y坐标，单位px）
        y: '190',
        itemWidth: 6,   // 设置图例图形的宽
        itemHeight: 6,  // 设置图例图形的高
        textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
            fontSize: 16,
            fontFamily: 'PingFang SC',
            fontWeight: 400,
            rich: {
                lcd: {
                    // color: 'rgba(255, 255, 255, 0.85)',
                    fontSize: 20,
                    fontFamily: 'lcd',
                    fontWeight: 'bold',
                    padding: [0, 0, 4, 8],
                    color: '#00CBFF',
                    lineHeight: 50
                },
                online: {
                    fontSize: 16,
                    fontFamily: 'PingFang SC',
                    fontWeight: 400,
                    padding: [0, 0, 0, -16] // 让数字对齐
                }
            }
        },
        // itemGap设置各个item之间的间隔，单位px，默认为10，横向布局时为水平间隔，纵向布局时为纵向间隔
        itemGap: 16,
        backgroundColor: 'transparent',  // 设置整个图例区域背景颜色
        data: ['正常','离线', '在线率'],
        formatter: (name) => {
            if (name === '在线率') {
                return `{online|${name}}:{lcd|-}`
            }
            return `${name}:{lcd|-}`
        }
    },
    series: [
        {
            name: '视频故障状况统计',
            type: 'pie',
            radius: ['47%', '55%'],  // 设置环形饼状图， 第一个百分数设置内圈大小，第二个百分数设置外圈大小
            center: ['50%', '38%'],  // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
            avoidLabelOverlap: false,
            label: {
                normal: {
                    show: true,
                    position: 'center',
                    // formatter: '{total|总数}'+ '\n\r'+ '\n\r' + '{count|-}',
                    rich: {
                        total:{
                            fontSize: 16,
                            fontFamily: "PingFang SC",
                            color: 'rgba(255, 255, 255, 0.85)'
                        },
                        count: {
                            fontSize: 32,
                            fontFamily: "lcd",
                            color:'#FFFFFF',
                            fontWeight: 'bold'
                        }
                    }
                }
            },
            // emphasis: {
            //   label: {
            //     show: false,
            //     fontSize: '18',
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
                show: false
            },
            data: [
                {value: '-', name: '正常'},
                {value: 0, name: '离线'},
                {value: '-', name: '在线率'}
            ]
        }
    ]
}

// 国省干线,水运,客运(单个)图表的样式
let polarOthersSingle = {
    title: {
        text: '',
        left: 'center',
        textStyle: {
            color: 'rgba(255, 255, 255, 0.85)'
        }
    },
    color: ['#00FFDC','#383B76', '#12145c'],
    legend: {
        selectedMode: false, //取消图例上的点击事件
        align:'left',
        // bottom: '40%',
        icon:'circle',
        // orient 设置布局方式，默认水平布局，可选值：'horizontal'（水平） ¦ 'vertical'（垂直）
        orient: 'vertical',
        // x 设置水平安放位置，默认全图居中，可选值：'center' ¦ 'left' ¦ 'right' ¦ {number}（x坐标，单位px）
        x: 'center',
        // y 设置垂直安放位置，默认全图顶端，可选值：'top' ¦ 'bottom' ¦ 'center' ¦ {number}（y坐标，单位px）
        y: '240',
        itemWidth: 6,   // 设置图例图形的宽
        itemHeight: 6,  // 设置图例图形的高itemGap
        textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
            fontSize: 16,
            fontFamily: 'PingFang SC',
            fontWeight: 400,
            rich: {
                lcd: {
                    // color: 'rgba(255, 255, 255, 0.85)',
                    fontSize: 20,
                    fontFamily: 'lcd',
                    fontWeight: 'bold',
                    padding: [0, 0, 4, 8],
                    color: '#00CBFF',
                    lineHeight: 50
                },
                online: {
                    fontSize: 16,
                    fontFamily: 'PingFang SC',
                    fontWeight: 400,
                    padding: [0, 0, 0, -16] // 让数字对齐
                }
            }
        },
        // itemGap设置各个item之间的间隔，单位px，默认为10，横向布局时为水平间隔，纵向布局时为纵向间隔
        itemGap: 36,
        backgroundColor: 'transparent',  // 设置整个图例区域背景颜色
        data: ['正常','离线'],
        formatter: (name) => {
            return `${name}:{lcd|20}`
        }
    },
    series: [
        {
            name: '',
            type: 'pie',
            radius: ['47%', '55%'],  // 设置环形饼状图， 第一个百分数设置内圈大小，第二个百分数设置外圈大小
            center: ['50%', '34%'],  // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
            avoidLabelOverlap: false,
            label: {
                normal: {
                    show: true,
                    position: 'center',
                    // formatter: '{total|总数}'+ '\n\r'+ '\n\r' + '{count|-}',
                    formatter: '{total|高速公路}\n\r\n\r{count|10}',
                    rich: {
                        total:{
                            fontSize: 16,
                            fontFamily: "PingFang SC",
                            color: 'rgba(255, 255, 255, 0.85)'
                        },
                        count: {
                            fontSize: 32,
                            fontFamily: "lcd",
                            color:'#FFFFFF',
                            fontWeight: 'bold'
                        }
                    }
                }
            },
            // emphasis: {
            //   label: {
            //     show: false,
            //     fontSize: '18',
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
                show: false
            },
            data: [
                {value: '-', name: '正常'},
                {value: 0, name: '离线'}
            ]
        }
    ]
}

// 国省干线,水运,客运(多个)图表的样式
let polarOthersMulti = {
    title: {
        text: '',
        left: 'center',
        textStyle: {
            color: 'rgba(255, 255, 255, 0.85)'
        }
    },
    color: ['#00FFDC','#383B76', '#12145c'],
    legend: {
        selectedMode: false, //取消图例上的点击事件
        align:'left',
        // bottom: '40%',
        icon:'circle',
        // orient 设置布局方式，默认水平布局，可选值：'horizontal'（水平） ¦ 'vertical'（垂直）
        orient: 'horizontal',
        // x 设置水平安放位置，默认全图居中，可选值：'center' ¦ 'left' ¦ 'right' ¦ {number}（x坐标，单位px）
        x: 'center',
        // y 设置垂直安放位置，默认全图顶端，可选值：'top' ¦ 'bottom' ¦ 'center' ¦ {number}（y坐标，单位px）
        y: '120',
        itemWidth: 6,   // 设置图例图形的宽
        itemHeight: 6,  // 设置图例图形的高itemGap
        textStyle: {
            color: 'rgba(255, 255, 255, 0.85)',
            fontSize: 16,
            fontFamily: 'PingFang SC',
            fontWeight: 400,
            rich: {
                lcd: {
                    // color: 'rgba(255, 255, 255, 0.85)',
                    fontSize: 20,
                    fontFamily: 'lcd',
                    fontWeight: 'bold',
                    padding: [0, 0, 4, 8],
                    color: '#00CBFF',
                    lineHeight: 50
                },
                online: {
                    fontSize: 16,
                    fontFamily: 'PingFang SC',
                    fontWeight: 400,
                    padding: [0, 0, 0, -16] // 让数字对齐
                }
            }
        },
        // itemGap设置各个item之间的间隔，单位px，默认为10，横向布局时为水平间隔，纵向布局时为纵向间隔
        itemGap: 12,
        backgroundColor: 'transparent',  // 设置整个图例区域背景颜色
        data: ['正常','离线'],
        formatter: (name) => {
            return `${name}:{lcd|-}`
        }
    },
    series: [
        {
            name: '',
            type: 'pie',
            radius: ['55%', '60%'],  // 设置环形饼状图， 第一个百分数设置内圈大小，第二个百分数设置外圈大小
            center: ['35%', '44%'],  // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
            avoidLabelOverlap: false,
            label: {
                normal: {
                    show: true,
                    position: 'center',
                    // formatter: '{total|总数}'+ '\n\r'+ '\n\r' + '{count|-}',
                    // formatter: '{total|国省干线}\n\r{count|10}',
                    rich: {
                        total:{
                            fontSize: 16,
                            fontFamily: "PingFang SC",
                            color: 'rgba(255, 255, 255, 0.85)'
                        },
                        count: {
                            fontSize: 24,
                            fontFamily: "lcd",
                            color:'#FFFFFF',
                            fontWeight: 'bold'
                        }
                    }
                }
            },
            // emphasis: {
            //   label: {
            //     show: false,
            //     fontSize: '18',
            //     fontWeight: 'bold'
            //   }
            // },
            labelLine: {
                show: false
            },
            data: [
                {value: '-', name: '正常'},
                {value: 0, name: '离线'},
            ]
        }
    ]
}

let timeout = null

export default {
  name: "MonitorVideoStatistics",
  components: {
      PanelTitle,
      MonitorVideoStatisticsChart,
    'v-chart': ECharts
  },
    // props: {
    //   resourceData: { // 资源数据
    //       type: Array,
    //       default: () => []
    //   }
    // },
    computed: {
      hasHighway () {
          return this.resourceData.filter(item => item.leve1 === INDUSTRY_TYPE.高速公路.value).length > 0
      },
      hasRoad () {
          return this.resourceData.filter(item => item.leve1 === INDUSTRY_TYPE.国省干线.value).length > 0
      },
      hasWater () {
          return this.resourceData.filter(item => item.leve1 === INDUSTRY_TYPE.水运.value).length > 0
      },
      hasPassenger () {
          return this.resourceData.filter(item => item.leve1 === INDUSTRY_TYPE.客运.value).length > 0
      },
      totalAccessCount () { // 总接入数
          let totalAccess = 0
          if (this.hasHighway) {
              if (this.highwayData.dataSource !== null) {
                  totalAccess += this.highwayData.dataSource.reported
              }
          }
          if (this.hasRoad) {
              // if (this.roadData.dataSource !== null) {
              //     totalAccess += this.roadData.dataSource.reported
              // }
          }
          if (this.hasWater) {
              // if (this.waterData.dataSource !== null) {
              //     totalAccess += this.waterData.dataSource.reported
              // }
          }
          if (this.hasPassenger) {
              // if (this.passengerData.dataSource !== null) {
              //     totalAccess += this.passengerData.dataSource.reported
              // }
          }
          // 临时-默认加上新接路段数据
          if (this.newRoadData.dataSource !== null) {
              totalAccess += this.newRoadData.dataSource.reported
          }
          return totalAccess
      },
        totalAccessRate () { // 总接入率
            let totalCount = 0
            if (this.hasHighway) {
                if (this.highwayData.dataSource !== null) {
                    totalCount += this.highwayData.dataSource.totalCount
                }
            }
            if (this.hasRoad) {
                // if (this.roadData.dataSource !== null) {
                //     totalCount += this.roadData.dataSource.totalCount
                // }
            }
            if (this.hasWater) {
                // if (this.waterData.dataSource !== null) {
                //     totalCount += this.waterData.dataSource.totalCount
                // }
            }
            if (this.hasPassenger) {
                // if (this.passengerData.dataSource !== null) {
                //     totalCount += this.passengerData.dataSource.totalCount
                // }
            }
            // 临时-默认加上新接路段数据
            if (this.newRoadData.dataSource !== null) {
                totalCount += this.newRoadData.dataSource.totalCount
            }
            return parseInt(this.totalAccessCount / totalCount * 100)
        }
    },
  data () {
    return {
        Corner,
        RESOURCE_TYPE,
        spinning: {
            highway: false,
            road: false,
            water: false,
            passenger: false,
            newRoad: false // 临时-新接路段加载状态
        }, // 加载中...
        panelVisible: true, // 面板是否显示
        panelExpand: false, // 面板是否展开
        panelExpandIcon: require('../assets/images/iconfont/btn_cardopen.png'),
        arrow: Corner.ArrowRight,
        panelWidth: 290, // 面板宽度
        chartWidth: 250, // 统计图表宽度
        chartOthersWidth: 290, // 其他行业统计图表宽度
        chartCountHighway: 0, // 统计图表数量(高速公路)
        chartCountOthers: 0, // 统计图表数量(其他)
        resourceData: [],
        chartOptions: {
            highway: [], // 高速公路图表
            others: [] // 其他(国省干线,水运,客运)图表
        },
        highwayData: null, // 高速公路图表数据
        roadData: null,
        waterData: null,
        passengerData: null,
        newRoadData: null, // 临时-新接路段数据
        showVisibleButtonFlag: false, // 是否展示面板的显隐按钮
        showSwitchButtonFlag: true, // 是否展示图标的切换箭头按钮
        refreshNum: 0, // 控制刷新效果的flag,第一次进入不显示特效
        jtbVisible: false // 交通部是否可见
    }
  },
  methods:{
    getCameraTotalData(){
        this.refreshNum++
        this.chartOptions = {
            highway: [], // 高速公路图表
            others: [] // 其他(国省干线,水运,客运)图表
        };
        this.chartCountHighway = 0 // 图表数量,用于计算面板展开宽度
        this.chartCountOthers = 0 // 图表数量,用于计算面板展开宽度
        if (this.hasHighway) { // 有数据权限才去查数据
            this.jtbVisible = true

            this.chartCountHighway++
            this.getHighwayData()
            // 临时-默认加上新接路段数据(有高速才有新接路段)
            this.chartCountHighway++
            this.getNewRoadData()
        }
        if (this.hasRoad) {
            this.chartCountOthers++
            this.getRoadData()
        }
        if (this.hasWater) {
            this.chartCountOthers++
            this.getWaterData()
        }
        if (this.hasPassenger) {
            this.chartCountOthers++
            this.getPassengerData()
        }
    },
      getHighwayData () {
          this.spinning.highway = true
          const roleId = Vue.ls.get('user').roleIds[0] // 角色id
          // getCameraTotal(roleId).then((res)=>{
          getCameraTotalTemp(roleId).then((res)=>{
              let data = res.data
              // 在线率
              let onlineRate = data.totalCount > 0 ? parseInt(data.online / data.totalCount * 100) : 0
              if (data !== null && data !== undefined) {
                  this.highwayData = deepClone(polarHighway)
                  this.highwayData.series[0].label.normal.formatter = `{total|${INDUSTRY_TYPE.高速公路.label}}\n\r\n\r{count|${data.totalCount}}`
                  this.highwayData.series[0].data[0].value = data.online // 在线数
                  this.highwayData.series[0].data[1].value = data.offline // 离线数
                  // this.highwayData.series[0].data[2].value = 0 // 在线率
                  // this.highwayData.title.text = INDUSTRY_TYPE.高速公路.label
                  this.highwayData.legend.formatter = (name) => {
                      if (name === '正常') {
                          // return name + ': ' + '{lcd|' + data.online + '}'
                          return `${name}:{lcd|${data.online}}`
                      } else if (name === '离线') {
                          // return name + ': ' + data.offline
                          return `${name}:{lcd|${data.offline}}`
                      } else if (name === '在线率') {
                          // return name + ': ' + onlineRate + '%'
                          return `{online|${name}}:{lcd|${onlineRate}%}`
                      }
                  }
                  this.highwayData.dataSource = data
              }
              this.highwayData.sort = 1
              this.chartOptions.highway.push(this.highwayData)
              this.spinning.highway = false
          }).catch((err)=>{
              console.log(err)
          })
      },
      getRoadData () { // 查询国省干线数据
          this.spinning.road = true
          const roleId = Vue.ls.get('user').roleIds[0] // 角色id
          getBureauCameraTotal(roleId).then((res)=>{
              let data = res.data
              // 在线率
              let onlineRate = data.totalCount > 0 ? parseInt(data.online / data.totalCount * 100) : 0
              if (data !== null && data !== undefined) {
                  if (this.chartCountOthers === 1) {
                      this.roadData = deepClone(polarOthersSingle)
                  } else {
                      this.roadData = deepClone(polarOthersMulti)
                  }
                  this.roadData.series[0].label.normal.formatter = `{total|${INDUSTRY_TYPE.国省干线.label}}\n\r\n\r{count|${data.totalCount}}`
                  this.roadData.series[0].data[0].value = data.totalCount > 0 ? data.online : '-'// 在线数
                  this.roadData.series[0].data[1].value = data.offline // 离线数
                  // this.roadData.series[0].data[2].value = 0 // 在线率
                  // this.highwayData.title.text = INDUSTRY_TYPE.高速公路.label
                  this.roadData.legend.formatter = (name) => {
                      if (name === '正常') {
                          // return name + ': ' + '{lcd|' + data.online + '}'
                          return `${name}:{lcd|${data.online}}`
                      } else if (name === '离线') {
                          // return name + ': ' + data.offline
                          return `${name}:{lcd|${data.offline}}`
                      } else if (name === '在线率') {
                          // return name + ': ' + onlineRate + '%'
                          return `{online|${name}}:{lcd|${onlineRate}%}`
                      }
                  }
                  this.roadData.dataSource = data
              }
              this.roadData.sort = 3
              this.chartOptions.others.push(this.roadData)
              this.spinning.road = false
          }).catch((err)=>{
              console.log(err)
          })
/*          this.spinning.road = true
          this.roadData = deepClone(polar)
          // this.roadData.title.text = '国省干线'
          this.roadData.series[0].label.normal.formatter = `{total|${INDUSTRY_TYPE.国省干线.label}}\n\r\n\r{count|-}`

          // this.roadData.legend.formatter = (name) => {
          //     if (name === '正常') {
          //         // return name + ': ' + '{lcd|' + data.online + '}'
          //         return name + ':{lcd|' + data.online + '}'
          //     } else if (name === '离线') {
          //         // return name + ': ' + data.offline
          //         return name + ':{lcd|' + data.offline + '}'
          //     } else if (name === '在线率') {
          //         // return name + ': ' + onlineRate + '%'
          //         return name + ':{lcd|' + onlineRate + '%}'
          //     }
          // }

          this.roadData.sort = 3
          this.roadData.dataSource = null
          this.chartOptions.push(this.roadData)
          this.spinning.road = false*/
      },
      getWaterData () { // 查询水运数据
          this.spinning.water = true
          const roleId = Vue.ls.get('user').roleIds[0] // 角色id
          getWaterCameraTotal(roleId).then((res)=>{
              let data = res.data
              // 在线率
              let onlineRate = data.totalCount > 0 ? parseInt(data.online / data.totalCount * 100) : 0
              if (data !== null && data !== undefined) {
                  if (this.chartCountOthers === 1) {
                      this.waterData = deepClone(polarOthersSingle)
                  } else {
                      this.waterData = deepClone(polarOthersMulti)
                  }
                  this.waterData.series[0].label.normal.formatter = `{total|${INDUSTRY_TYPE.水运.label}}\n\r\n\r{count|${data.totalCount}}`
                  this.waterData.series[0].data[0].value = data.totalCount > 0 ? data.online : '-'// 在线数
                  this.waterData.series[0].data[1].value = data.offline // 离线数
                  this.waterData.legend.formatter = (name) => {
                      if (name === '正常') {
                          // return name + ': ' + '{lcd|' + data.online + '}'
                          return `${name}:{lcd|${data.online}}`
                      } else if (name === '离线') {
                          // return name + ': ' + data.offline
                          return `${name}:{lcd|${data.offline}}`
                      } else if (name === '在线率') {
                          // return name + ': ' + onlineRate + '%'
                          return `{online|${name}}:{lcd|${onlineRate}%}`
                      }
                  }
                  this.waterData.dataSource = data
              }
              this.waterData.sort = 4
              this.chartOptions.others.push(this.waterData)
              this.spinning.water = false
          }).catch((err)=>{
              console.log(err)
          })
      },
      //查询客运数据
      getPassengerData () {
          this.spinning.passenger = true
          // this.passengerData = deepClone(polarOthersMulti)
          // // this.passengerData.title.text = '客运'
          // this.passengerData.series[0].label.normal.formatter = `{total|${INDUSTRY_TYPE.客运.label}}\n\r\n\r{count|-}`
          // this.passengerData.sort = 5
          // this.passengerData.dataSource = null
          // this.chartOptions.others.push(this.passengerData)
          // this.spinning.passenger = false
          const roleId = Vue.ls.get('user').roleIds[0] // 角色id
          getPassengerCameraTotal(roleId).then((res)=>{
              let data = res.data
              // 在线率
              let onlineRate = data.totalCount > 0 ? parseInt(data.online / data.totalCount * 100) : 0
              if (data !== null && data !== undefined) {
                  if (this.chartCountOthers === 1) {
                      this.passengerData = deepClone(polarOthersSingle)
                  } else {
                      this.passengerData = deepClone(polarOthersMulti)
                  }
                  this.passengerData.series[0].label.normal.formatter = `{total|${INDUSTRY_TYPE.客运.label}}\n\r\n\r{count|${data.totalCount}}`
                  this.passengerData.series[0].data[0].value = data.totalCount > 0 ? data.online : '-'// 在线数
                  this.passengerData.series[0].data[1].value = data.offline // 离线数
                  this.passengerData.legend.formatter = (name) => {
                      if (name === '正常') {
                          // return name + ': ' + '{lcd|' + data.online + '}'
                          return `${name}:{lcd|${data.online}}`
                      } else if (name === '离线') {
                          // return name + ': ' + data.offline
                          return `${name}:{lcd|${data.offline}}`
                      } else if (name === '在线率') {
                          // return name + ': ' + onlineRate + '%'
                          return `{online|${name}}:{lcd|${onlineRate}%}`
                      }
                  }
                  this.passengerData.dataSource = data
              }
              this.passengerData.sort = 5
              this.chartOptions.others.push(this.passengerData)
              this.spinning.passenger = false
          }).catch((err)=>{
              console.log(err)
          })
      },
      getNewRoadData () { // 临时-查询新接路段统计图
          this.spinning.newRoad = true
          const roleId = Vue.ls.get('user').roleIds[0] // 角色id
          getCameraTotalNewRoadTemp(roleId).then((res)=>{
              let data = res.data
              // 在线率
              let onlineRate = data.totalCount ? parseInt(data.online / data.totalCount * 100) : 0
              if (data !== null && data !== undefined) {
                  this.newRoadData = deepClone(polarHighway)
                  this.newRoadData.series[0].label.normal.formatter = `{total|独立运营路段}\n\r\n\r{count|${data.totalCount}}`
                  this.newRoadData.series[0].data[0].value = data.online // 在线数
                  this.newRoadData.series[0].data[1].value = data.offline // 离线数
                  this.newRoadData.series[0].data[2].value = 0 // 在线率
                  this.newRoadData.legend.formatter = (name) => {
                      if (name === '正常') {
                          // return name + ': ' + '{lcd|' + data.online + '}'
                          return `${name}:{lcd|${data.online}}`
                      } else if (name === '离线') {
                          // return name + ': ' + data.offline
                          return `${name}:{lcd|${data.offline}}`
                      } else if (name === '在线率') {
                          // return name + ': ' + onlineRate + '%'
                          return `{online|${name}}:{lcd|${onlineRate}%}`
                      }
                  }
                  this.newRoadData.dataSource = data
              }
              this.newRoadData.sort = 2
              this.chartOptions.highway.push(this.newRoadData)
              this.spinning.newRoad = false
          }).catch((err)=>{
              console.log(err)
          })
      },
    testfunction(name){
      let returnName = '';
      chartData.forEach((item)=>{
        if(item.name == name){
          returnName = name + '：'+item.value
          return
        }
      })
      return returnName
    },
      changeJtbVisible (show) {
        if (show) {
            setTimeout(() => {
                this.jtbVisible = show
            }, 200)
        } else {
            this.jtbVisible = show
        }
      },
      showVisibleButton (flag) {
        this.showVisibleButtonFlag = flag
      },
      // hidePanel () {
      //   this.$refs.panelTitle.hidePanel(this.$refs.layerControl)
      //     // this.$refs.layerControl.style.opacity = 0
      //     // setTimeout(() => {
      //     //     this.$refs.layerControl.style.visibility = 'hidden'
      //     // }, 500)
      //     // this.panelVisible = false
      // },
      // showPanel () {
      //     // this.panelVisible = true
      //     this.$refs.layerControl.style.visibility = 'visible'
      //     this.$refs.layerControl.style.opacity = 1
      // },
      hidePanel () { //隐藏面板
          // if(!isClick) { // 防止疯狂点击出事
              this.$refs.layerControl.classList.remove('flipInY')
              this.$refs.layerControl.classList.add('fadeOut')
              // isClick = true
              // this.panelDom.style.opacity = 0
              setTimeout(() => {
                  this.$refs.layerControl.style.visibility = 'hidden'
                  // isClick = false
              }, 500)

              // 隐藏展开按钮
              this.showVisibleButton(true)
          // }
      },
      showPanel () { // 显示面板
          // isClick = false
          this.$refs.layerControl.classList.remove('fadeOut')
          this.$refs.layerControl.classList.add('fadeIn')

          this.$refs.layerControl.style.visibility = 'visible'
          // this.panelDom.style.opacity = 1
          this.$eventBus.$emit('eventPanelExpand', false)

          // 隐藏展开按钮
          this.showVisibleButton(false)
      },
      getResourceData () { // 查询资源数据
          const roleId = Vue.ls.get('user').roleIds[0] // 角色id
          listResource(roleId).then(res => {
              this.resourceData = res.data

              this.getCameraTotalData()

              /*
              setInterval(() => {
                  this.getCameraTotalData()
              }, this.$refreshInterval)
              */
                // 使用setTimeout实现
              clearTimeout(timeout)
              timeout = null
              const timeoutFn = () => {
                  timeout = setTimeout(() => {
                      this.getCameraTotalData()
                      timeoutFn()
                  }, this.$refreshInterval + 7000) // 随便加个时间,错开刷新时间,减轻服务器压力
              }
              timeoutFn()
          })
      },
      panelExpandClick () { // 展开统计图面板
        this.$nextTick(() => {
            // 数据加载完毕才能操作,不然无法计算面板宽度
            // if (this.spinning.highway || this.spinning.road || this.spinning.water || this.spinning.passenger) {
            //     return
            // }
            if (this.panelExpand) { // 面板是展开状态
                this.shrinkPanel()
                this.$refs.monitorVideoStatisticsChart.scrollReset() // 收缩面板时滚动重置
            } else {
                this.expandPanel()
            }
        })
      },
      expandPanel () { // 展开面板
          this.panelExpandIcon = require('../assets/images/iconfont/btn_cardclose.png')
          this.panelExpand = true
          this.showSwitchButtonFlag = false
          // this.panelWidth = (this.chartCountHighway + (this.chartCountOthers > 0 ? 1 : 0)) * this.chartWidth
          this.panelWidth = this.chartCountHighway * this.chartWidth + (this.chartCountOthers > 0 ? 1 : 0) * this.chartOthersWidth
          if (this.chartCountHighway > 0) {
              this.jtbVisible = true
          }
      },
      shrinkPanel () { // 收缩面板
          this.panelExpandIcon = require('../assets/images/iconfont/btn_cardopen.png')
          this.panelExpand = false
          this.showSwitchButtonFlag = true
          this.panelWidth = 290
      },
  },
  mounted() {
      this.$nextTick(() => {
          this.getResourceData()

          this.$eventBus.$on('onlinePanelVisible', (visible) => {
              if (visible) {
                  this.showPanel()
              } else {
                  this.hidePanel()
              }
          })
      })
  },
    beforeDestroy() {
        clearTimeout(timeout)
        timeout = null
    }

}
</script>

<style lang="less" scoped>

  .panel-corner {
    & img:nth-child(1) {
      position: absolute;
      top: -2px;
      left: -2px;
    }

    & img:nth-child(2) {
        position: absolute;
        right: -2px;
        top: -2px;
      }

    & img:nth-child(3) {
        position: absolute;
        bottom: -2px;
        left: -2px;
      }

    & img:nth-child(4) {
        position: absolute;
        right: -2px;
        bottom: -2px;
    }
  }

  .pie-icon {
      background-color: rgba(21, 23, 97, 0.85);
      position: absolute;
      left: -48px;
      top: 14px;
      width: 48px;
      border-radius: 6px 0px 0px 6px;
      border-top: 2px solid #0088ff;
      border-left: 2px solid #0088ff;
      border-bottom: 2px solid #0088ff;

      div {
          cursor: pointer;
          &:hover {
              img {
                  filter: brightness(1.4);
              }
          }
      }

      img {
          width: 30px;
          height: 30px;
          margin: 8px;
      }
  }

  .layerControl {
    /*width: 290px;*/
    /*width: 974px;*/
    height: 590px;
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 10;
    background: rgba(21, 23, 97, 0.85);
    /*transition: right 0.6s;*/
    transition: opacity 0.5s, width 0.5s;
  }
  .layerTop {
    height: 16px;
    background-image: url("../assets/images/div360_16/360_16Up.png");
  }

  .layerMiddle {
    height: 307px;
    background-image: url("../assets/images/div360_16/360_16Center.png");
  }

  .layerBottom {
    height: 16px;
    background-image: url("../assets/images/div360_16/360_16Down.png");
  }
  .layerContent {
    position: relative;
    top: 5px;
    width: 100%;
    height: 590px;
    text-align: center;
  }
  .chart-name-div{
    margin-top: 16px;
    width: 128px;
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 22px;
    color: #0088FF;
    opacity: 1;
  }

    .visible-button {
        position: absolute;
        right: 16px;
        top: 16px;
        cursor: pointer;
        z-index: 10;
    }

    .visible-button-hide {
        &:hover {
            content: url("../assets/images/iconfont/btn_closewindow_hover.png");
        }
    }

    .visible-button-show {
        &:hover {
            content: url("../assets/images/iconfont/btn_video_hover.png");
        }
    }

      .switch-button {
          img {
              z-index: 9;
              position: absolute;
              cursor: pointer;
              top: 294px;
          }

          .switch-button-prev {
              left: 15px;
              &:hover {
                  content: url("../assets/images/iconfont/btn_leftarrow_hover.png");
              }
          }
          .switch-button-next {
              right: 15px;
              &:hover {
                  content: url("../assets/images/iconfont/btn_rightarrow_hover.png");
              }
          }
      }

    .card-show-button {
        position: absolute;
        top: 45%;
        left: -15px;
        cursor: pointer;
        &:hover {
            filter: brightness(1.2);
        }
    }

  .jtb-info {
      border-radius: 4px;
      background: rgba(0, 203, 255, 0.16);
      height: 48px;
      line-height: 48px;
      /*display: inline-flex;*/
      /*justify-content: center; !*定义body的里的元素水平居中*!;*/
      /*align-items: center; !*定义body的元素垂直居中*!*/
      /*vertical-align: middle;*/
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 24px;
      /*margin: auto;*/
      margin-left: 34px;
      transition: width 0.5s;
      overflow: hidden;

      .jtb-logo {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #FFFFFF;
      }

      .access-info-item-text {
          width: 45px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.85);

          border-left: 2px solid rgba(0, 203, 255, 0.65);
          padding-left: 16px;
      }

      .access-info-middle-line {
          height: 60%;
          width: 2px;
          background-color: rgba(0, 203, 255, 0.45);
      }

      .access-info-item-number {
          width: 58px;
          font-size: 24px;
          font-family: lcd;
          font-weight: 800;
          color: #00CBFF;
      }
  }




</style>
