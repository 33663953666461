<!--视频在线数统计图表组件-->
<template>
<!--    <div class="chart-container" :style="{width: panelExpand ? 250 * (this.chartOptions.highway.length + (this.chartOptions.others.length > 0 ? 1 : 0)) + 'px' : '250px'}" ref="chartContainer">-->
    <div class="chart-container" :style="{width: panelExpand ? 790 + 'px' : '250px'}" ref="chartContainer">
        <div class="statistics-chart" v-for="(chartOption, index) of chartOptions.highway" :key="index" :style="{order: chartOption.sort, marginLeft: panelExpand ? '' : '20px'}">
            <!--chart图表-->
            <v-chart style="width: 250px; height: 292px;" :options="chartOption"></v-chart>

            <!--瀑布效果图片-->
            <div class="middle-line">
                <img src="../../assets/images/iconfont/bg_translate.gif">
            </div>

            <!--接入数&接入率-->
            <div class="access-info">
                <div class="access-info-item">
                    <div>接入数</div>
                    <div class="access-info-item-number">{{chartOption.dataSource !== null ? chartOption.dataSource.reported : '-'}}</div>
                </div>
                <div class="access-info-middle-line"></div>
                <div class="access-info-item">
                    <div>接入率</div>
                    <div class="access-info-item-number">{{chartOption.dataSource !== null ? chartOption.dataSource.reportedRate + '%' : '-'}}</div>
                </div>
            </div>


        </div>
        <div class="dividing-line" />

        <!--一条数据时-->
        <div v-if="chartOptions.others.length === 1" :style="{order: chartOptions.others[0].sort}">
            <v-chart style="width: 250px; height: 332px;" :options="chartOptions.others[0]"></v-chart>
            <div class="online-rate-one" v-if="chartOptions.others[0].dataSource !== null">
                在线率：{{ chartOptions.others[0].dataSource.totalCount > 0 ? parseInt(chartOptions.others[0].dataSource.online / chartOptions.others[0].dataSource.totalCount * 100) : 0}}%
            </div>
        </div>


        <div v-else-if="chartOptions.others.length > 1" class="multi-chart" :style="{order: chartOptions.others[0].sort}">
            <div style="width: 290px; height: 160px;" :style="{order: chartOption.sort}" v-for="(chartOption, index) of chartOptions.others" :key="index">
                <v-chart style="width: 290px; height: 160px;" :options="chartOption"></v-chart>
                <div class="chart-background">
                    <span class="online-rate-multi" style="top: 18px;">在线率</span>
                    <span class="online-rate-multi" style="bottom: 18px; font-size: 20px;" v-if="chartOption.dataSource !== null">{{chartOption.dataSource.totalCount > 0 ? parseInt(chartOption.dataSource.online / chartOption.dataSource.totalCount * 100) : 0}}%</span>
                    <span class="online-rate-multi" style="bottom: 18px; font-size: 20px;" v-else>-%</span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

    import ECharts from 'vue-echarts'
    import 'echarts/index'

    export default {
        name: "MonitorVideoStatistics",
        components: {
            'v-chart': ECharts
        },
        props: {
            chartOptions: { // 统计图表数据
              type: Object,
              default: () => {}
          },
            panelExpand: { // 面板是否处于展开状态
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                currentIndex: 0 // 当前显示第几个图表

            }
        },
        methods: {
            switchChart (direct) { // 图表轮播
                this.$nextTick(() => {
                    let scale = [] // 滚动刻度尺
                    // let count = this.chartOptions.length
                    let count = this.chartOptions.highway.length + (this.chartOptions.others.length > 0 ? 1 : 0)
                    // for (let i = 0; i < count; i++) {
                    //     scale.push(i *250)
                    // }

                    if (this.chartOptions.highway.length > 0) { // 高速公路,新接路段
                        scale = [0, 270]
                        if (this.chartOptions.others.length > 0) { // 高速公路,新接路段,其他
                            scale.push(540)
                        }
                    } else { // 其他
                        if (this.chartOptions.others.length > 0) {
                            scale = [270]
                        }
                    }

                    if (direct === 'prev') {
                        this.currentIndex--
                    } else if (direct === 'next') {
                        this.currentIndex++
                    }

                    if (this.currentIndex < 0) {
                        this.currentIndex = count - 1
                    }
                    if (this.currentIndex >= count) {
                        this.currentIndex = 0
                    }

                    if (this.currentIndex > 1) {
                        this.$emit('changeJtbVisible', false) // 其他行业,隐藏交通部
                    } else {
                        this.$emit('changeJtbVisible', true)
                    }

                    // 滚动到指定位置
                    if (this.$refs.chartContainer !== undefined) {
                        this.$refs.chartContainer.scrollTo({
                            left: scale[this.currentIndex],
                            behavior: 'smooth'
                        })
                    }
                })
            },
            scrollReset () { // 面板合上后重置滚动
                this.currentIndex = 0
            }
        },
        mounted() {
            this.$nextTick(() => {
                if (this.chartOptions.highway.length + (this.chartOptions.others.length > 0 ? 1 : 0) > 1) {
                    setInterval(() => { // 自动轮播20s
                        if (!this.panelExpand) {
                            this.switchChart('next')
                        }
                    }, 20000)
                }
            })
        }

    }
</script>

<style lang="less" scoped>

    .chart-container {
        display: flex;
        overflow: hidden;
        transition: width 0.5s;
        height: 536px;
    }

    .statistics-chart {
        text-align: center;
        position: relative;

        .middle-line {
            margin-top: 16px;
        }

        .access-info {
            width: 180px;
            height: 68px;
            border: 2px solid rgba(0, 203, 255, 0.45);
            border-radius: 8px;

            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.85);

            display: inline-flex;
            align-items: center; /*定义body的元素垂直居中*/


            .access-info-middle-line {
                height: 100%;
                width: 2px;
                background-color: rgba(0, 203, 255, 0.45);
            }

            .access-info-item {
                width: 88px;

                .access-info-item-number {
                    font-size: 22px;
                    font-family: lcd;
                    font-weight: bold;
                    color: #00CBFF;
                    opacity: 1;
                }
            }
        }
    }

    .dividing-line {
        position: absolute;
        width: 2px;
        left: 500px;
        bottom: 30px;
        height: 480px;
        background: rgb(0, 136, 255);
        opacity: 0.45;
    }

    .online-rate-one {
        margin: 0px auto;
        position: relative;
        width: 140px;
        height: 68px;
        border: 2px solid rgba(0, 203, 255, 0.45);
        border-radius: 8px;
        line-height: 68px !important;
        bottom: -56px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: rgba(255, 255, 255, 0.85);
        opacity: 1;
    }

    .multi-chart {
        position: relative;
        margin: auto 0px;
        display: grid;

        .chart-background {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 36px;
            color: rgba(255, 255, 255, 0.85);
            width:200px;
            height: 100px;
            position: relative;
            top: -139px;
            left: 50px;
            background-image: url('../../assets/images/iconfont/chart_background.png');

            .online-rate-multi {
                position: absolute;
                left: 120px;
                font-family: lcd;
            }
        }
    }



</style>
